import React from 'react';
import { getCountry } from '../../Services/ElegService';
import { userContext } from '../../App';

const DisabledSelect = (props) => {
    return <select disabled {...props} />
}

//Cuando este listo el problema del nivel 4 sacar el disable
const EnabledSelect = (props) => {
    return <select {...props} />
}

class InputCountry extends React.Component {
    
    constructor(props) {
        super(props);
        this.parentSetter = props.foo;
        this.state = {
            value: "",
            options: [
                { key: "Cargando", value: "Cargando" }
            ],
            enabled: false,
            
            warning: props.warning,
        }
        
        this._handleChange = this._handleChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    
    _handleChange(value) {
        this.setState({ value: value });
        if (this.parentSetter) {
            this.parentSetter(value);
        }
    }
    
    handleChange(event) {
        this._handleChange(event.target.value);
    }
    
    componentDidMount() {
        const p = getCountry();
        p.then(res => {
            this.setState({
                options: res,
                enabled: true
            })
            this._handleChange(res[0].value);
        })
    }
    
    componentDidUpdate(prevProps) {
        if (this.props.warning !== prevProps.warning) {
            this.setState({
                warning: this.props.warning
            })
        }
    }
    
    render() {
        return (
            <userContext.Consumer>
                {(context) => {
                    const selectProps = {
                        id: "country",
                        className: `custom-select ${this.state.warning ? 'border-warning' : ''}`,
                        value: this.state.value,
                        onChange: this.handleChange,
                        children: this.state.options.map(function (item) {
                            let optionProps = {
                                key: item.key,
                                value: item.value,
                                children: item.value,
                            };

                            return <option {...optionProps} />;
                        })
                    };

                    console.log(context.plan)
                    return (
                        <div className="col-lg-4">
                            <div className="form-group mb-4">
                                <label htmlFor="country">País</label>
                                {context.plan !== 'active' ?  <DisabledSelect {...selectProps} /> : <EnabledSelect {...selectProps} /> }
                            </div>
                        </div>
                    );
                }}
            </userContext.Consumer>
        );
    }
}

export default InputCountry;