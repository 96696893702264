import React, {useState} from 'react';
import {ImagenBanner, InputForm, MessageSuccess} from "../Components";
import {Text, useTranslator} from "@eo-locale/react";
import {budgetRequest} from "../Services/ElegService";

const useColorModeValue = () => {}
const useDisclosure = () => {}

const Box = (props) => {
    return <div {...props} />
}

const Textarea = (props) => {
    return <div {...props} />
}

const Button = (props) => {
    return <div {...props} />
}

const Grid = (props) => {
    return <div {...props} />
}

const FormControl = (props) => {
    return <div {...props} />
}

const FormLabel = (props) => {
    return <p {...props} />
}


const BudgetView = () => {
    const translator = useTranslator();
    const [query, setQuery] = useState({});
    const [success, setSuccess] = useState(false);
    const genericSet = (e) => {
        const {id, value} = e.target;
        setQuery({...query, [id]: value})
    }

    const handlerSubmit = (e) => {
        e.preventDefault();
        budgetRequest(query)
            .then(r => {
                if (r === query) {
                    setSuccess(true)
                }
            });
    }

    return (
        <Box>
            <ImagenBanner/>
            {!success ?
                <form onSubmit={handlerSubmit}>
                    <Grid templateColumns={{md: 'repeat(2, 100fr)'}} margin={"10"}>
                        <Box>
                            <Box height={20}>
                                <InputForm idFormControl="nameAndLastname" idTextLocalization='NameAndLastname'
                                           onChange={genericSet}
                                           placeholder={'EnterNameOrDescription'}
                                />
                            </Box>
                            <Box height={20}>
                                <InputForm idFormControl="email" idTextLocalization='Email'
                                           onChange={genericSet}
                                           placeholder={'EnterNameOrDescription'}
                                />
                            </Box>
                            <Box height={20}>
                                <InputForm idFormControl="corporateName" idTextLocalization='CorporateName'
                                           onChange={genericSet}
                                           placeholder={'EnterNameOrDescription'}
                                />
                            </Box>
                            <Box height={20}>
                                <InputForm idFormControl="tel" idTextLocalization='Tel'
                                           onChange={genericSet}
                                           placeholder={'EnterNameOrDescription'}
                                />
                            </Box>
                            <Box height={20}>
                                <InputForm idFormControl="contactTime" idTextLocalization='ContactTime'
                                           onChange={genericSet}
                                           placeholder={'EnterNameOrDescription'}
                                />
                            </Box>
                        </Box>
                        <Box ml={{md: '1.5rem'}}>
                            <FormControl id={'message'} height={'100%'}>
                                <FormLabel fontWeight={"bold"}><Text id={'Message'}/></FormLabel>
                                <Textarea onChange={genericSet} height={"sm"} placeholder={translator.translate('EnterNameOrDescription')}/>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Box textAlign={"center"}>
                        <Button
                            width={'25%'}
                            bgGradient="linear(#ADA34D 0%,#D6C851 100%)"
                            borderRadius={29}
                            color={"white"}
                            _hover={{
                                bgGradient: "linear(#ADA34D 0%,#D6C851 50%)"
                            }}
                            _active={{
                                bgGradient: "linear(#ADA34D 0%,#D6C851 30%)"
                            }}
                            p={'25px'}
                            type={'submit'}
                        >
                            <Text id={"BudgetRequest"}/>
                        </Button>
                    </Box>
                </form>
                :
                <MessageSuccess/>
            }
        </Box>

    );
};

export default BudgetView;
