const Footer = () => {
    return (
        <footer id="layout-footer" className="site-footer py-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 pb-5">
                        <div className="footer-brand text-center text-md-left"><img src="assets/images/logo.svg" alt="logo-eleg" /></div>
                        <div className="d-flex align-items-center justify-content-center justify-content-md-start ml-4">
                            <a href="https://www.linkedin.com/company/estrucplan-consultora-s-a-/?irgwc=1" target="_blank" rel="noreferrer"><img
                                src="assets/images/icon-linkedin.svg" alt="linkedin" /></a>
                            <a href="https://www.youtube.com/watch?v=4qOzYmvImXg" className="mx-4" target="_blank" rel="noreferrer"><img
                                src="assets/images/icon-youtube.svg" alt="youtube" /></a>
                        </div>
                        <div className="col-12 text-center text-md-left pt-5 pt-m-0 px-4">
                            <a href="http://qr.afip.gob.ar/?qr=zqRaoP0atdpSlecxOSSOuw,," target="_F960AFIPInfo"><img className="afip-image" alt="" src="https://www.afip.gob.ar/images/f960/DATAWEB.jpg" border="0"/></a>
                        </div>
                    </div>
                    <div className="text-center text-sm-left col-sm-6 col-md-4 pb-5">
                        <div className="footer-title">Contacto</div>
                        <ul className="list-contact-media">
                            <li><a href="mailto:eleg@estrucplan.com.ar:">eleg@estrucplan.com.ar</a></li>
                            <li><a href="https://estrucplan.com.ar/" className="btn btn-sm btn-danger px-2" target="_blank" rel="noreferrer"><b>Estrucplan</b> Consultora S.A.</a></li>
                        </ul>
                    </div>
                    <div className="text-center text-sm-left col-sm-6 col-md-4">
                        <div className="footer-title">Productos</div>
                        <ul className="list-footer-menu">
                            <li><a href="https://eleg.app/plan-basico">Buscador de Legislación Básico</a></li>
                            <li><a href="https://eleg.app/plan-avanzado">Buscador de Legislación Avanzado</a></li>
                            <li><a href="https://eleg.app/#nuestros-productos">Matriz on line Profesional</a></li>
                            <li><a href="http://eleg.app/matrices">Matriz on line Premium</a></li>
                            <li><a href="https://eleg.app/#auditorias">Auditorías de Cumplimiento in situ o a distancia</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;