import React, { useContext, useEffect } from 'react';
import { userContext } from "../../App";
import { getUserStatus } from "../../Services/ElegService";

const UpperBanner = () => {

    const { user, plan, setPlan } = useContext(userContext);

    const userPlan = plan;

    // FIXME: Obtain from config
    const freeTierMonthlySearchs = 5;
    const freeTierResultLimit = 10;

    const name = (user && user.profile && user.profile.given_name) ? (" " + user.profile.given_name) : "";

    const renderPlans = () => {
        if (userPlan === "loading") { // Estado de carga
            return (<p className="text-white">...<strong></strong><br />...</p>)
        }
        if (userPlan === "free") { // Plan gratuito
            return (<p className="text-white">En tu <strong>Plan Basico</strong> tenés hasta {freeTierMonthlySearchs} busquedas por mes y hasta {freeTierResultLimit} resultados por cada una.</p>)
        }
        if (userPlan === "inactive") { // Plan similar a gratuito pero el usuario alguna vez tuvo premium
            // TODO: Personalizar mensaje
            return (<p className="text-white">En tu <strong>Plan Basico</strong> tenés hasta {freeTierMonthlySearchs} busquedas por mes y hasta {freeTierResultLimit} resultados por cada una.<br />Cambiate a <strong><a href='https://eleg.app/plan-avanzado'>Plan Avanzado</a></strong> , no tendrás límites y podrás utilizar otros filtros para mejorar tu búsqueda.</p>)
        }
        if (userPlan === "active") { // Plan premium
            return (<p className="text-white">En tu <strong>Plan Avanzado</strong><br />¡Buscá sin límite y con filtros avanzados!</p>)
        }
        // En el caso que ocurra un error en la consulta de estado o se reciba un estado que no se conoce
        // TODO: Personalizar mensaje 
        return (<p className="text-white">En tu <strong>Plan Basico</strong> tenés hasta {freeTierMonthlySearchs} busquedas por mes y hasta {freeTierResultLimit} resultados por cada una.<br />Cambiate a <strong><a href='https://eleg.app/plan-avanzado'>Plan Avanzado</a></strong> , no tendrás límites y podrás utilizar otros filtros para mejorar tu búsqueda.</p>)
    }

    useEffect(() => {
        if (user) {
            if (user.id_token && user.profile && user.profile.email) {
                getUserStatus(user.profile.email, user.id_token).then((res) => {
                    if (res) {
                        setPlan(res);
                    } else {
                        setPlan("error");
                    }
                }).catch(_ => {
                    setPlan("error");
                });
            }
        }
    }, [setPlan, user])

    return (
        <section className="internal-header header-plan-avanzado d-flex align-items-end">
            <div className="container">
                <div className="row d-flex align-items-center justify-content-between">
                    <div className=" text-left  col-md-6 mb-2">
                        <h1 className="title pt-5 pt-md-0">¡Hola{name}!</h1>
                        {renderPlans()}
                    </div>
                    {userPlan !== "active" ?
                        userPlan === "loading" ?
                            <></>
                            :
                            <div class=" text-center col-md-6">
                                <div class="text-left">
                                    <div class="row">
                                        <div class=" custom-breack-point  col-lg-2 col-xl-4 "></div>
                                        <div class=" col-lg-10 col-xl-8 ">
                                            <a href='https://eleg.app/plan-avanzado' className="btn-plan-avanzado-suscribirse-medium">Suscribirse al Plan Avanzado</a>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class=" custom-breack-point   col-lg-2 col-xl-4 col-sm-2 "></div>
                                        <div class=" text-left col-lg-10   col-xl-8 col-sm-12 col-md-12">
                                        <ul className='custom-list-plan-avanzado mt-3'>
                                            <li><span> Alcance Municipal.</span></li>
                                            <li> <span> Busqueda por palabras y rango de fechas.</span></li>
                                            <li> <span> Consultas ilimitadas. Sin tope de resultados.</span></li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                            </div>
                        :
                        <></>
                    }
                </div>
            </div >
        </section >
    );
};

/* Esto es el boton de pago 
<div className="col-md-4">
                                <div className="text-center text-light">
                                    <h4 className="font-weight-bold mb-2">Suscribirse por:</h4>
                                    <div role="group" className="btn-group price-period w-100">
                                        <button type="button" className="w-100 btn btn-light mx-auto my-auto d-block">
                                            <a href='https://clientes.eleg.app/cart.php?a=add&pid=6&carttpl=standard_cart&currency=1' className='color-black'>
                                                <div className="btn-price-content">
                                                    <span className="btn-price-title">USD 12</span>
                                                    <span className="btn-price-period">valor mensual</span>
                                                </div>
                                            </a>
                                        </button>
                                        <button type="button" className="w-100 btn btn-light mx-auto d-block">
                                            <a href='https://clientes.eleg.app/cart.php?a=add&pid=6&carttpl=standard_cart&currency=1' className='color-black'>
                                                <div className="btn-price-content">
                                                    <span className="btn-price-title">USD 100</span>
                                                    <span className="btn-price-period">valor anual</span>
                                                </div>
                                            </a>
                                        </button>
                                    </div>
                                </div>
                            </div>

*/
export default UpperBanner;