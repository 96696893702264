/**
 * En este objeto se esta mockeando el objeto usuario ya que todavia n es posible recuperarlo de forma automatica.
 * */
let UserMock = {
    "email": "dilan.bernabe@squad.com.ar",
    "email_verified": false,
    "name": "Dilan Bernabe",
    "family_name": "Bernabe",
    "given_name": "Dilan",
    "preferred_username": "dilan.bernabe@squad.com.ar",
    "locale": "en-GB",
    "update_at": "2021-06-02 13:31:17",
    "sub": "6246f2a0-42cc-4f0d-8837-fd97a5246452"
};
export default UserMock;