import Moment from 'moment';
import { ElegRepository } from "../Repository";
const elegRepository = new ElegRepository();

const prefix = 'ELEG_';


const validateCache = (persistedData, time, timeUnit) => {
    const _time = time ? time : 1;
    const _timeUnit = timeUnit ? timeUnit : "hours";

    if (persistedData == null) {
        console.debug("Null data");
        return false;
    }
    if (!Moment(Moment.now()).isBefore(Moment(persistedData.created).add(_time, _timeUnit))) {
        console.debug("Old data");
        return false;
    }
    if (persistedData.data == null) {
        console.debug("Incompleted data");
        return false;
    }
    if (Array.isArray(persistedData.data) && !persistedData.data.length) {
        console.debug("Data is an empty array");
        return false;
    }

    return true;
}

const handleRequest = async (callable, ...params) => {
    let remoteData = [];
    await callable(...params)
        .then(response => {
            remoteData = response
        });

    if (remoteData == null) {
        if (params) {
            throw params;
        } else {
            throw "Error";
        }
    }

    return new Promise((resolve) => { resolve(remoteData); })
}

const handleRequestWithCache = async (key, callable, ...params) => {

    const persistedDataRaw = localStorage.getItem(key);

    if (persistedDataRaw != null) {
        try {
            let persistedData = JSON.parse(persistedDataRaw);

            if (validateCache(persistedData)) {

                // CACHE

                return new Promise((resolve) => { resolve(persistedData.data) });
            }
        } catch (error) {
            // Continue
        }
    }

    // REPOSITORY

    let remoteData = [];
    await callable(...params)
        .then(response => {
            remoteData = response
        });

    //Check for error
    if (remoteData == null) {
        throw key;
    }

    let object = {
        data: remoteData,
        created: Moment(Moment.now())
    };

    localStorage.setItem(key, JSON.stringify(object));

    return new Promise((resolve) => { resolve(object.data); })

}

export const getCountry = async () => {
    let key = prefix + 'l1';
    return handleRequestWithCache(key, elegRepository.getCountry);
}

export const getNormType = async (country) => {
    let key = prefix + country + '_' + 'nt';
    return handleRequestWithCache(key, elegRepository.getNormType, country);
}

export const getScope = async (country) => {
    let key = prefix + country + '_' + 'sc';
    return handleRequestWithCache(key, elegRepository.getScope, country);
}

export const getLevel2 = async (country) => {
    let key = prefix + country + '_' + 'l2';
    return handleRequestWithCache(key, elegRepository.getLevel2, country);
}

export const getLevel3 = async (country, level2) => {
    let key = prefix + country + '_' + level2 + '_' + 'l3';
    return handleRequestWithCache(key, elegRepository.getLevel3, country, level2);
}

export const getLevel4 = async (country, level2, level3) => {
    let key = prefix + country + '_' + level2 + '_' + level3 + '_' + 'l4';
    return handleRequestWithCache(key, elegRepository.getLevel4, country, level2, level3);
}

export const getIssuer = async (country) => {
    let key = prefix + country + '_' + 'is';
    return handleRequestWithCache(key, elegRepository.getIssuer, country);
}

export const getNormas = async (query, header) => {
    return handleRequest(elegRepository.getNormas, query, header);
}

export const getUserStatus = async (email, bearer) => {
    return handleRequest(elegRepository.getUserStatus, email, bearer);
}

//FIXME: Esto deberia conectarse con backend para poder solicitar presupuesto.
export const budgetRequest = async (query) => {
    let result = {}
    elegRepository.budgetRequest(query)
        .then(r => {
            result = r;
        });
    return result;
}