import React, { useContext, useEffect, useState } from 'react';
import { SelectForm, InputForm, HiddeableInputForm, ResultList, ImagenBanner, UpgradePlanModal, NoResults, Error } from "../Components";
import { getCountry, WhmcsService } from "../Services";
import { getNormas, getNormType, getScope, getLevel2, getLevel3, getLevel4 } from "../Services/ElegService";
import { Text } from "@eo-locale/react";
import { userContext } from "../App";

const cascadeDelete = {
    country: ['scope', 'level2', 'level3', 'level4', 'normType'],
    level2: ['level3', 'level4'],
    level3: ['level4'],
    scope1: ['level2', 'level3', 'level4'],
    scope2: ['level3', 'level4'],
    scope3: ['level4'],
    scope4: [],
}

const Accordion = (props) => {
    return <div {...props} />
}

const AccordionButton = (props) => {
    return <input {...props} />
}

const AccordionItem = (props) => {
    return <p {...props} />
}

const Box = (props) => {
    return <div {...props} />
}

const AccordionIcon = (props) => {
    return <p {...props} />
}

const AccordionPanel = (props) => {
    return <div {...props} />
}

const Grid = (props) => {
    return <div {...props} />
}

const Skeleton = (props) => {
    return <div {...props} />
}


const SearchView = () => {
    const whmcs = new WhmcsService();
    const [country, setCountry] = useState([]);
    const [scope, setScope] = useState([]);
    const [normType, setNormType] = useState([]);

    const [formNetworkError, setFormNetworkError] = useState(undefined);

    const [scopeStrength, setScopeStrength] = useState(-1);
    const [scopeDynamicLabel, setScopeDynamicLabel] = useState(["Nivel1", "Nivel2", "Nivel3"])

    const [level2, setLevel2] = useState([]);
    const [level3, setLevel3] = useState([]);
    const [level4, setLevel4] = useState([]);

    const [query, setQuery] = useState({});
    const [result, setResult] = useState({});
    const [loadingResult, setLoadingResult] = useState(false);
    const [emptyResult, setEmptyResult] = useState(false);

    const [selectCountry, setSelectCountry] = useState(false);
    const [selectScope, setSelectScope] = useState(false);
    const [selectNormType, setSelectNormType] = useState(false);
    const [selectLevel2, setSelectLevel2] = useState(false);
    const [selectLevel3, setSelectLevel3] = useState(false);
    const [selectLevel4, setSelectLevel4] = useState(false);

    const defaultCountry = "Argentina"; // Esto se podria conseguir por session o por user

    const [valueCountry, setValueCountry] = useState(defaultCountry);
    const [valueScope, setValueScope] = useState(undefined);
    const [valueNormType, setValueNormType] = useState(undefined);
    const [valueYear, setValueYear] = useState(undefined);
    const [valueLevel2, setValueLevel2] = useState(undefined);
    const [valueLevel3, setValueLevel3] = useState(undefined);
    const [valueLevel4, setValueLevel4] = useState(undefined);
    const [queryString, setQueryString] = useState("");
    const [payAdvanced, setPayAdvanced] = useState(false);
    const { user } = useContext(userContext)
    const [prevQuery, setPrevQuery] = useState({});

    const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

    const [openUgradePlan, setOpenUgradePlan] = useState(false);

    

    const networkError = (e) => {
        setFormNetworkError(e);
    }

    // useEffect(() => {
    //     if (user !== undefined) {
    //         whmcs.getClients({ search: user["profile"]["preferred_username"] })
    //             .then(res => {
    //                 whmcs.getClientsProducts(res.response.data.clients.client[0].id)
    //                     .then(res2 => {
    //                         console.log(res2);
    //                         if (res2.response.data.products) {
    //                             res2.response.data.products.product
    //                                 .forEach(prod => {
    //                                     if ((prod.name === 'Avanzado') && (prod.status === 'Active')) {
    //                                         setPayAdvanced(true);
    //                                     } else {
    //                                         setOpenUgradePlan(true);
    //                                     }
    //                                 })
    //                         } else {
    //                             setOpenUgradePlan(true);
    //                         }
    //                     })
    //             })
    //     }
    // }, [user]);


    useEffect(() => {
        getCountry()
            .then(r => {
                setCountry(r);
                setSelectCountry(true);
                handlerInputCountry({
                    target: {
                        value: defaultCountry,
                        id: 'country'
                    }
                });
            }).catch(networkError);
    }, []);

    let lastRequestedValueCountry = "";

    useEffect(() => {

        setScopeStrength(0);

        setSelectScope(false);
        setValueScope("");
        setSelectNormType(false);
        setValueNormType("");
        setSelectLevel2(false);
        setValueLevel2("");
        if (valueCountry != null && valueCountry !== "") {

            lastRequestedValueCountry = valueCountry;

            getScope(valueCountry)
                .then(r => {
                    if (lastRequestedValueCountry === valueCountry) {
                        setScope(r);
                        setScopeDynamicLabel(r.slice(1).map((n) => {
                            if (n.label != null) {
                                return n.label;
                            } else {
                                return n.text;
                            }
                        }))
                        setSelectScope(true);
                    }
                }).catch(networkError);

            getNormType(valueCountry)
                .then(r => {
                    if (lastRequestedValueCountry === valueCountry) {
                        setNormType(r);
                        setSelectNormType(true);
                    }
                }).catch(networkError);

            getLevel2(valueCountry)
                .then(r => {
                    if (lastRequestedValueCountry === valueCountry) {
                        setLevel2(r);
                        setSelectLevel2(true);
                    }
                }).catch(networkError);

        }
    }, [valueCountry])

    useEffect(() => {
        if (valueScope != null && valueScope !== "") {

            const newValue = scope.findIndex((n) => n.value === valueScope);
            const oldValue = scopeStrength;

            if (oldValue < 1) {
                setSelectLevel3(false);
            }
            else if ((oldValue === 1) || (oldValue === 2 && newValue === 1)) {
            }
            else if (oldValue === 2 && newValue < 1) {
                setValueLevel2("");
            }
            else if (oldValue === 2 && newValue >= 3) {
            }
            else if (oldValue === 3) {
                if (newValue === 2) {
                    if (!valueLevel3) setSelectLevel4(false);
                    setValueLevel4("");
                }
                else if (newValue === 1) {
                    setSelectLevel4(false);
                    setValueLevel4("");
                    if (!valueLevel2) setSelectLevel3(false);
                    setValueLevel3("");
                }
                else if (newValue < 1) {
                    setSelectLevel4(false);
                    setValueLevel4("");
                    setSelectLevel3(false);
                    setValueLevel3("");
                    setValueLevel2("");
                }
            }
            setScopeStrength(newValue);
        }
    }, [valueScope])

    const isEmptyArray = (a) => {
        return (Array.isArray(a) && a.length !== 0)
    }

    useEffect(() => {
        setSelectLevel2(false)
        setSelectLevel3(false);
        setLevel3([]);
        setValueLevel3("");
        if (valueLevel2 != null && valueLevel2 !== "") {
            getLevel3(valueCountry, valueLevel2)
                .then(r => {
                    setLevel3(r);
                    setSelectLevel3(true);
                    setSelectLevel2(true)
                }).catch(networkError);

        } else {
            setSelectLevel2(isEmptyArray(level2));
        }
    }, [valueLevel2])

    useEffect(() => {
        setSelectLevel3(false)
        setSelectLevel4(false);
        setLevel4([]);
        setValueLevel4("");
        if (valueLevel3 != null && valueLevel3 !== "") {
            getLevel4(valueCountry, valueLevel2, valueLevel3)
                .then(r => {
                    setLevel4(r);
                    setSelectLevel4(true);
                    setSelectLevel3(true)
                }).catch(networkError);

        } else {
            setSelectLevel3(isEmptyArray(level3));
        }
    }, [valueLevel3])

    const genericSet = (e) => {
        const { id, value } = e.target;
        setQuery({ ...query, [id]: value })
    }
    const resetFields = (key) => {
        cascadeDelete[key]?.forEach(keyField => {
            if (keyField in query) {
                delete query[keyField];
            }
        })
    }

    const resetFieldsByScope = (scope) => {
        resetFields('scope' + scope);
    }

    const handlerInputCountry = (e) => {
        let { value } = e.target;
        setValueCountry(value);
        resetFields('country');
        genericSet(e);
    }

    const handlerInputNormType = (e) => {
        //FIXME: validar campo.
        genericSet(e);
    }
    const handlerInputYear = (e) => {
        //FIXME: validar campo.
        let { value } = e.target;
        setValueYear(value);
        genericSet(e);
    }

    const handlerInputNNorm = (e) => {
        //FIXME: validar campo.
        genericSet(e);
    }
    const handlerInputnScope = (e) => {
        let { value, selectedIndex } = e.target;
        setValueScope(value);
        resetFieldsByScope(selectedIndex);
        genericSet(e);
    }

    const handlerInputnLevel2 = (e) => {
        let { value } = e.target;
        setValueLevel2(value);
        resetFields('level2');
        genericSet(e);
    }
    const handlerInputnLevel3 = (e) => {
        let { value } = e.target;
        setValueLevel3(value);
        resetFields('level3');
        genericSet(e);
    }
    const handlerInputnLevel4 = (e) => {
        let { value } = e.target;
        setValueLevel4(value);
        genericSet(e);
    }

    const handlerSearchByWord = (e) => {
        //FIXME: validar campo.
        genericSet(e);
    };
    const handlePagination = (pageSize, offset) => {
        setPrevQuery({ ...prevQuery, offset: offset, pageSize: pageSize })
    }

    const switchShowAdvancedFilters = (e) => {
        setShowAdvancedFilters(prevState => !prevState);
        e.preventDefault();
    }

    useEffect(() => {
        //FIXME: en un futuro deberia cambiarse a que todos los campos obligatorios esten cargados, por ahora se queda en scope
        //FIXME: si cambia cualquier otro campo que no sea offset o pageSize se deberian resetear estos.
        if (query.scope !== undefined) {
            setLoadingResult(true);
            getNormas(query)
                .then(r => {
                    setResult(r);
                    setLoadingResult(false);
                }).catch(networkError);
        }
    }, [prevQuery.offset]);

    const queryToString = (query) => {
        //TODO: a definir por cliente. #39
        let s = "Resultados de "
        for (let key in query) {
            s += key + ":" + query[key] + "; ";
        }
        return s;
    }

    const handlerSubmit = (e) => {
        e.preventDefault();
        setEmptyResult(false)
        setLoadingResult(true);
        setQuery({ ...query, offset: 0, pageSize: 4 })
        setQueryString(queryToString(query));
        console.log(query)
        getNormas(query)
            .then(r => {
                if (r.count === 0) {
                    setResult(r);
                    setEmptyResult(true);
                } else {
                    setResult(r);
                }
                setLoadingResult(false);
                setPrevQuery(query);
            }).catch(networkError);
    }


    const AdvancedFilter = () => {
        return (
            <Accordion defaultIndex={[1]} width={{ md: "30%" }} margin={"5"} allowMultiple
                onClick={() => !payAdvanced && setOpenUgradePlan(true)}>
                <AccordionItem borderStyle={"none"} id={1} isDisabled={!payAdvanced}>
                    <AccordionButton>
                        <Box flex="1" textAlign="left">
                            <Text id={"TextFilterAdvance"} />
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4}>
                        <InputForm idFormControl="searchByWord" idTextLocalization='searchByWord'
                            onChange={handlerSearchByWord}
                        />
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        )
    }

    const NextLine = () => {
        return <div className="col-12"></div>
    }

    const SimpleButton = (props) => {
        return (
            <div className="col-lg-4 text-center text-md-left">
                <a onClick={props.onClick} href="#" className="text-link text-decoration-none" id="mostrar-filtros-avanzados">
                    {props.children}
                </a>
            </div>
        )
    }

    const ButtonIcon = (props) => {
        return (<i className="d-flex mr-2"><img src={props.src} /></i>)
    }

    const PrincipalButton = (props) => {
        return (<button className="btn-buscar" {...props} />)
    }

    const Row = (props) => {
        return (<div className="row" {...props} />)
    }

    const Center = (props) => {
        return (<div className="d-flex justify-content-center pt-4" {...props} />)
    }

    const RenderForm = () => (
        <section className="py-5 features plan-avanzado elegForm">
            <form className="container">
                <Row>
                    <SelectForm value={valueCountry} isDisabled={!selectCountry} idFormControl="country"
                        idTextLocalization='Country' options={country}
                        onChange={handlerInputCountry} defaultIndex={defaultCountry}
                    />
                    <SelectForm value={valueNormType} isDisabled={!selectNormType} idFormControl="normType"
                        idTextLocalization='NormType' options={normType}
                        placeholderSelect="Select option"
                        onChange={handlerInputNormType}
                    />
                    <InputForm value={valueYear} idFormControl="year" idTextLocalization='Year'
                        onChange={handlerInputYear}
                    />
                    <InputForm idFormControl="nroNorma" idTextLocalization='nNorm'
                        onChange={handlerInputNNorm}
                    />
                    <NextLine />
                    <SelectForm value={valueScope}
                        idFormControl="scope" idTextLocalization='Scope' options={scope}
                        placeholderSelect="Select option"
                        onChange={handlerInputnScope}
                        payAdvanced={payAdvanced}
                        isDisabled={!selectScope}
                    />
                    {(scopeStrength > 0) ?
                        <SelectForm value={valueLevel2}
                            idFormControl="level2" idTextLocalization={scopeDynamicLabel[0]} options={level2}
                            placeholderSelect="Select option"
                            onChange={handlerInputnLevel2}
                            isDisabled={!selectLevel2}
                        /> :
                        <></>}
                    {(scopeStrength > 1) ?
                        <SelectForm value={valueLevel3}
                            idFormControl="level3" idTextLocalization={scopeDynamicLabel[1]} options={level3}
                            placeholderSelect="Select option"
                            onChange={handlerInputnLevel3}
                            isDisabled={!selectLevel3}
                        /> :
                        <></>}
                    {(scopeStrength > 2) ?
                        <SelectForm value={valueLevel4}
                            idFormControl="level4" idTextLocalization={scopeDynamicLabel[2]} options={level4}
                            placeholderSelect="Select option"
                            onChange={handlerInputnLevel4}
                            isDisabled={!selectLevel4}
                        /> :
                        <></>}
                    <NextLine />

                    <HiddeableInputForm isHide={!showAdvancedFilters} idFormControl="palabrasInput" idTextLocalization='Busqueda por palabras'
                        onChange={handlerSearchByWord} placeholder="Introduzca nombre o descripción"
                    />

                    <NextLine />
                    <SimpleButton onClick={switchShowAdvancedFilters}>
                        {showAdvancedFilters ? "Ocultar filtros avanzados" : "Mostrar filtros avanzados"}
                    </SimpleButton>
                </Row>

                <Center>
                    <PrincipalButton onClick={handlerSubmit} >
                        <ButtonIcon src="assets/images/icon-search.svg" /> Buscar
                    </PrincipalButton>
                </Center>
            </form>
        </section>
    )

    const renderForm2 = () => (
        <form onSubmit={handlerSubmit} style={{}}>
            <Grid
                templateColumns={{ md: 'repeat(4, 100fr)' }}
                margin={"5"}
                gap={3}
                paddingTop={'2rem'}
                paddingLeft={'4rem'}
                paddingBottom={'2rem'}>
                <Box height={20}>
                    <Skeleton isLoaded={selectCountry}>
                        <SelectForm idFormControl="country" idTextLocalization='Country' options={country}
                            onChange={handlerInputCountry} defaultIndex={defaultCountry}
                        />
                    </Skeleton>
                </Box>

                <Box height={20}>
                    <Skeleton isLoaded={selectNormType}>
                        <SelectForm idFormControl="normType" idTextLocalization='NormType' options={normType}
                            placeholderSelect="Select option"
                            onChange={handlerInputNormType}
                        />
                    </Skeleton>
                </Box>
                {/* <Box height={20}>
                    <InputForm idFormControl="year" idTextLocalization='Year'
                        onChange={handlerInputYear}
                    />
                </Box> */}
                {/* <Box height={20}>
                    <InputForm idFormControl="nroNorma" idTextLocalization='nNorm'
                        onChange={handlerInputNNorm}
                    />
                </Box> */}
                {/* <Box height={20}>
                    <Skeleton isLoaded={selectScope}>
                        <SelectForm idFormControl="scope" idTextLocalization='Scope' options={scope}
                            placeholderSelect="Select option"
                            onChange={handlerInputnScope}
                            payAdvanced={payAdvanced}
                        />
                    </Skeleton>
                </Box> */}
                {(scopeStrength > 0) ?
                    <Box height={20}>
                        <SelectForm idFormControl="level2" idTextLocalization={scopeDynamicLabel[0]} options={level2}
                            placeholderSelect="Select option"
                            onChange={handlerInputnLevel2}
                            isDisabled={!selectLevel2}
                        />
                    </Box> :
                    <></>}
                {(scopeStrength > 1) ?
                    <Box height={20}>
                        <SelectForm idFormControl="level3" idTextLocalization={scopeDynamicLabel[1]} options={level3}
                            placeholderSelect="Select option"
                            onChange={handlerInputnLevel3}
                            isDisabled={!selectLevel3}
                        />
                    </Box> :
                    <></>}
                {(scopeStrength > 2) ?
                    <Box height={20} >
                        <SelectForm idFormControl="level4" idTextLocalization={scopeDynamicLabel[2]} options={level4}
                            placeholderSelect="Select option"
                            onChange={handlerInputnLevel4}
                            isDisabled={!selectLevel4}
                        />
                    </Box> :
                    <></>}
            </Grid>
            {/* {AdvancedFilter()} */}
            <Box textAlign={"center"}>
                {/* <Button
                    leftIcon={<BsSearch />}
                    bgGradient="linear(#ADA34D 0%,#D6C851 100%)"
                    borderRadius={29}
                    color={"white"}
                    _hover={{
                        bgGradient: "linear(#ADA34D 0%,#D6C851 50%)"
                    }}
                    _active={{
                        bgGradient: "linear(#ADA34D 0%,#D6C851 30%)"
                    }}
                    paddingLeft={"30px"}
                    paddingRight={"30px"}
                    type={'submit'}
                >
                    <Text id={"Search"} />
                </Button> */}
            </Box>
        </form>
    )

    const drawResults = () => {
        if (emptyResult === false) {
            return <ResultList result={result.list} lenght={result.count} handlePagination={handlePagination} loadingResult={loadingResult} />
        }
    }
    const noResults = () => {
        if (emptyResult) {
            return <NoResults />;
        }
    }
    return (
        <div>
            <ImagenBanner />
            <RenderForm />
            {/* <Center>
                <Box w="80%" marginTop={"3"} mx={"5"} >
                    <Heading as={'h3'} size="lg" color={'grey'}>{queryString}</Heading>
                    <Divider />
                </Box>
            </Center>
            {drawResults()}
            {noResults()}
            <UpgradePlanModal
                isOpen={openUgradePlan}
                onClose={() => setOpenUgradePlan(false)}
            /> */}
        </div>
    )
}

export default SearchView;