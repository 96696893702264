import './App.css';
import {TranslationsProvider} from '@eo-locale/react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
} from 'react-router-dom';
import {BudgetView, SearchView, SearchView2, Header, Footer} from "./Views";
import {Locales} from "./Utils";
import {createContext, useEffect, useState} from "react";
import {UserManager} from "oidc-client";
import {OidSettings} from "./Resources";
import Loading from "./Components/Loading";

export const userContext = createContext();

function App() {
    const siteLanguage = 'es'
    const [user, setUser] = useState();
    const [plan, setPlan] = useState("loading");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const userManager = new UserManager(OidSettings);
    const attemptMax= 3;

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    const resetAttempts=()=>{
        let attemp=0;
        localStorage.setItem('attempt',attemp.toString());
    }
    const incrementByOne = ()=>{
        if (!(localStorage.getItem('attempt') === null)){
            let attemp=1;
            localStorage.setItem('attempt',attemp.toString());
        }else{
            let attemp = parseInt(localStorage.getItem('attempt'));
            if (attemp>attemptMax){
                resetAttempts();
                setError(true);
            }
            attemp++;
            localStorage.setItem('attempt',attemp.toString());
        }
    }
    const refresh = () => {
        incrementByOne();
        localStorage.setItem('login', "false");
        window.location.reload();
    }

    /**
     * primera solucion a login automatico.
     * */
    useEffect(() => {
        let UserLoaded = false;
        if (!(localStorage.getItem('login') === "true")) {
            localStorage.setItem('login', "true");
            userManager.signinRedirect();
        } else {
            if ((window.location.search !== "")) {
                userManager.signinRedirectCallback(window.location)
                    .then(usr => {
                        localStorage.setItem('user', JSON.stringify(usr));
                        setUser(usr);
                        UserLoaded = true;
                        handlerLoading();
                    })
            }
        }
        if (!(localStorage.getItem('user') === null)) {
            handlerLoading();
            setUser(JSON.parse(localStorage.getItem('user')))
        } else {
            sleep(4000).then(() => {
                if (!UserLoaded) {
                    refresh();
                }
            })
        }
    }, []);

    const handlerUser = (user) => {
        setUser(user)
    }

    const handlerLoading = () => {
        resetAttempts();
        setLoading(!loading)
    }
    return (

         error ?
                <p>error!!</p>
                :
            <userContext.Provider value={{
                user: user,
                userManager: userManager,
                setUser: handlerUser,
                handlerLoading: handlerLoading,
                plan: plan,
                setPlan: setPlan,
            }}>
                <TranslationsProvider locales={Locales} language={siteLanguage}>
                    {loading ?
                        <Loading/>
                        :
                        <Router>
                            <Header />
                            <Switch>
                                <Route exact path='/' component={SearchView2}/>
                                <Route exact path='/v1' component={SearchView}/>
                                <Route exact path='/budget' component={BudgetView}/>
                            </Switch>
                            <Footer />
                        </Router>
                    }
                </TranslationsProvider>
            </userContext.Provider>



    );
}

export default App;
