import React from 'react';
import { getScope } from '../../Services/ElegService';

const DisabledSelect = (props) => {
    return <select disabled {...props} />
}

const EnabledSelect = (props) => {
    return <select {...props} />
}

const emptyItem = {
    key: "0",
    label: "",
    text: "",
    value: "",
}

const standardSort = (a, b) => {
    if (a.value > b.value) {
        return 1;
    }
    if (a.value < b.value) {
        return -1;
    }
    return 0;
}

class InputLevel extends React.Component {

    constructor(props) {
        super(props);
        this.parentSetter = props.onChange;
        this.state = {
            value: "",
            options: [
                { key: "Cargando", value: "Cargando" }
            ],
            enabled: false,
            caption: "",
            arguments: "",
        }
        this.callback = props.callback;
        this.id = props.id;
        this.bar = props.bar;

        this._handleChange = this._handleChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    _handleChange(value) {
        this.setState({ value: value });
        if (this.parentSetter) {
            this.parentSetter(value);
        }
    }

    handleChange(event) {
        this._handleChange(event.target.value);
    }

    componentDidMount() {
        if (this.props.foo) {
            getScope(this.props.foo).then(res => {
                this.setState({
                    caption: res[this.bar].label || res[this.bar].value || ""
                })
            })
        }

        if (this.props.updateOn) {
            const p = this.callback(this.props.updateOn);
            p.then(res => {
                if (!Array.isArray(res) || !res[0] || res[0].value === undefined) {
                    // Fallo en el back.
                    return;
                }
                res.sort(standardSort);
                if (Array.isArray(res) && res[0]) {
                    if (res[0].value === "-") {
                        res[0] = emptyItem;
                    } else {
                        res.unshift(emptyItem);
                    }
                }
                this.setState({
                    options: res,
                    enabled: true
                })
                this._handleChange(res[0].value);
            })
        }
    }
    
    componentDidUpdate(prevProps) {
        if (this.props.foo !== prevProps.foo) {
            getScope(this.props.foo).then(res => {
                if (res.length < 3) {
                    this.setState({
                        caption: res[this.bar].label || res[this.bar].value || ""
                    })
                } else {
                    this.setState({
                        caption: ""
                    })
                }
            })
        }

        if (this.props.updateOn !== prevProps.updateOn) {
            if (!this.props.updateOn) {
                this.setState({
                    options: [
                        { key: "", value: "" }
                    ],
                    enabled: false
                })
            } else {
                const p = this.callback(this.props.updateOn);
                p.then(res => {
                    if (!Array.isArray(res) || !res[0] || res[0].value === undefined) {
                        // Fallo en el back.
                        return;
                    }
                    res.sort(standardSort);
                    if (Array.isArray(res) && res[0]) {
                        if (res[0].value === "-") {
                            res[0] = emptyItem;
                        } else {
                            res.unshift(emptyItem);
                        }
                    }
                    this.setState({
                        options: res,
                        enabled: true
                    })
                    this._handleChange(res[0].value);
                })
            }
        }
    }

    componentWillUnmount() {
        if (this.parentSetter) {
            this.parentSetter('');
        }
    }

    render() {

        const selectProps = {
            id: this.id,
            className: "custom-select",
            value: this.state.value,
            onChange: this.handleChange,
            children: this.state.options.map(function (item) {
                let optionProps = {
                    key: item.key,
                    value: item.value,
                    children: item.value,
                };

                return <option {...optionProps} />
            })
        }

        return (
            <>
                <label htmlFor={this.id}>{this.state.caption}</label>
                {this.state.enabled ? <EnabledSelect {...selectProps} /> : <DisabledSelect {...selectProps} />}
            </>
        )
    }
}

export default InputLevel;
