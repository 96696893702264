
const consume = async (urlApi, endpoint, body, method, bearer, headers) => {
    const url = `${urlApi}/${endpoint}`;

    const defaultHeaders = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': process.env.REACT_APP_URL_API,
    }

    const extraHeaders = bearer ? { 'Authorization': 'Bearer ' + bearer } : {};

    const optionalHeaders = headers ? headers : {};

    const options = {
        headers: { ...defaultHeaders, ...extraHeaders, ...optionalHeaders },
        method: method || (body ? 'POST' : 'GET')
    }

    const extraOptions = body ? { body: JSON.stringify(body) } : {}

    return await fetch(url, {
        ...options,
        ...extraOptions
    })
        .then(res => {
            return res.json()
        })
        .then((res) => {
            return res;
        }).catch((x) => {
            return null;
        })
}

const genericLegSearchConverter = (response) => {
    if (!response) {
        return null;
    }
    return response.map((e) => {
        return {
            key: e.Id, // no deberia ser necesario
            value: e.Nombre,
            text: e.Nombre,
            label: e.Nombre
        }
    })
}

const scopeLegSearchConverter = (response) => {
    if (!response) {
        return null;
    }
    const knownNouns = {
        "Provincial": "Provincia",
        "Municipal": "Municipio",
        "Nacional": "Nación",
        "Regional": "Región"
    }
    return response.map((e) => {
        return {
            key: e.Id, // no deberia ser necesario
            value: e.Nombre,
            text: e.Nombre,
            label: knownNouns[e.Nombre] || e.Nombre
        }
    })
}

const issuerResponseConverter = (response) => {
    if (!response) {
        return null;
    }
    return response.map((e) => {
        return {
            key: e.Id,
            value: e.Nombre,
            shortname: e.Abreviatura,
            l2: e.Region,
            l3: e.Provincia,
            l4: e.Municipio,
            scope: e.Alcance,
            level: e.Nivel,
            text: e.Nombre,
            label: e.Nombre
        }
    })
}

const searchResponseConverter = (response) => {
    if (!response || !response.Normas) {
        return null;
    }
    let foo = {
        list: [],
        pageSize: 5,
        count: 0,
    }
    foo.count = response.Total;
    foo.pageSize = response.TamanhoPagina;
    foo.list = response.Normas.map((e) => {
        return {
            id: e.Id,
            tipo: e.Tipo,
            numero: e.Numero,
            anho: e.Anho,
            organismoEmisor: e.OrganismoEmisor,
            alcance: e.Alcance,
            region: e.Region,
            provincia: e.Provincia,
            municipio: e.Municipio,
            content: e.Descripcion,
            fecha: e.Fecha,
            archivo: e.Archivo,
            vigente: e.Vigente,
            norma: e.Nombre,
            texto: e.Texto,
        }
    })
    return foo;
}

const userStatusResponseConverter = (response) => {
    if (!response || !response.Status) {
        return null;
    }
    return response.Status;
}

const URL_API = process.env.REACT_APP_URL_API;

class ElegRepository {

    getCountry = async () => {
        const response = await consume(URL_API, "countries");
        return genericLegSearchConverter(response);
    }

    getNormType = async (country) => {
        const response = await consume(URL_API, "types", { Pais: country });
        return genericLegSearchConverter(response);
    }

    getScope = async (country) => {
        const response = await consume(URL_API, "scopes", { Pais: country });
        return scopeLegSearchConverter(response);
    }

    getLevel2 = async (country) => {
        const response = await consume(URL_API, "level2", { Pais: country });
        return genericLegSearchConverter(response);
    }

    getLevel3 = async (country, level2) => {
        const response = await consume(URL_API, "level3", { Pais: country, Level2: level2 });
        return genericLegSearchConverter(response);
    }

    getLevel4 = async (country, level2, level3) => {
        const response = await consume(URL_API, "level4", { Pais: country, Level2: level2, Level3: level3 });
        return genericLegSearchConverter(response);
    }

    getIssuer = async (country) => {
        const response = await consume(URL_API, "issuer", { Pais: country });
        return issuerResponseConverter(response);
    }

    getNormas = async (query, bundle) => {
        const response = await consume(URL_API, "norms", { ...query }, 'POST', bundle.Token, { "Sub-Email": bundle.Mail });
        return searchResponseConverter(response);
    }

    getUserStatus = async (email, bearer) => {
        const response = await consume(URL_API, "user-status", { Email: email }, 'POST', bearer, { "Sub-Email": email })
        return userStatusResponseConverter(response);
    }

    budgetRequest = async (query) => {
        // FIXME
        return query
    }

}

export default ElegRepository;