import React from "react";
import { Text } from "@eo-locale/react";

const FormControl = (props) => {
    return (
        <div className="col-lg-4">
            <div className="form-group mb-4"  {...props} />
        </div>
    )
}

const Label = (props) => {
    return <label {...props} />
}

const SelectForm = (props) => {
    const {
        idFormControl,
        idTextLocalization,
        placeholderSelect,
        onChange,
        payAdvanced,
        value,
        isDisabled
    } = props;

    const handleOnChange = (e) => {
        if (onChange) {
            onChange(e);
        }
    }

    const Select = (props) => {
        return <select className="custom-select" onChange={handleOnChange} {...props} />
    }

    let selectProps = {
        id: idFormControl,
        value: value,
    };

    if (placeholderSelect != null) {
        selectProps = {
            ...selectProps,
            placeholder: placeholderSelect
        };
    }

    if (isDisabled != null) {
        selectProps = {
            ...selectProps,
            disabled: isDisabled,
        }
    }

    return (
        <FormControl>
            <Label htmlFor={idFormControl}><Text id={idTextLocalization} /></Label>
            <Select {...selectProps}> {
                props.options.map(function (item) {
                    let optionProps = {
                        key: item.key,
                        value: item.value,
                        children: item.value,
                    };

                    // FIXME. Just, Fixme
                    let disabled = payAdvanced === false && item.value === 'municipal'

                    return disabled ?
                        <option {...optionProps} disabled />
                        :
                        <option {...optionProps} />
                })
            }
            </Select>
        </FormControl>
    );
};

export default SelectForm;
