import React from 'react';
import { getLevel2 } from '../../Services/ElegService';
import InputLevel from './InputLevel';

class InputLevel2 extends React.Component {
    render() {
        return (
            <div className="col-lg-4">
                <div className="form-group mb-4">
                    <InputLevel {...this.props} id="level2" callback={getLevel2} bar={1} />
                </div>
            </div>
        )
    }
}

export default InputLevel2;
