import React from 'react';
import Modal from './Modal';
import Norm from './Norm';

class SearchResults extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            results: props.results || [],
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.results !== prevProps.results) {
            this.setState({ results: this.props.results });
        }
    }

    render() {
        const x = (i) => i.map((item) => item.name).toString();
        return <div className="col-12" key={x(this.state.results)}>
            {this.state.results.map((x, i) => <div key={`${i}${x.name}`}>
                <Norm value={x} index={i} />
                <Modal value={x} index={i} />
            </div>)}
        </div>
    }
}

export default SearchResults;