import React from 'react';
import DownloadPDFButton from './DownloadPDFButton';

const BASE_MODAL_URL = process.env.BASE_MODAL_URL || "seeMoreModal";

class Norm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            norm: props.value || {},
            index: props.index || 0,
        }

        this.isActive = this.isActive.bind(this);
    }

    isActive(active) {
        if (active) {
            return "Si";
        } else {
            return "No";
        }
    }

    renderProvincia(norm) {
        if (norm !== null) {
            return <div className="badge">
                <span className="col-label">Provincia: </span>
                <span className="col-title">{norm}</span>
            </div>
        }
    }

    renderMunicipio(norm) {
        if (norm !== null) {
            return <div className="badge">
                <span className="col-label">Municipio: </span>
                <span className="col-title">{norm}</span>
            </div>
        }
    }

    renderRegion(norm) {
        if (norm !== null && norm !== '-') {
            return <div className="badge">
                <span className="col-label">Region: </span>
                <span className="col-title">{norm}</span>
            </div>
        }
    }

    renderTrimBody(body) {
        if (body !== null) {
            return < p className="card-text" > {body.length > 250 ? `${body.substring(0, 250)}...` : body}</p >
        }
    }

    render() {
        return <div key={`norm${this.state.index}-${this.state.norm.name}`} className="card mb-3">
            <div className="card-header-search">
                <div className="card-header-text">
                    <span className="col-label">Norma</span>
                    <span className="col-title"><strong>{this.state.norm.name}</strong></span>
                    <div className="card-badge-status">
                        <span className="badge badge-dark">Vigente: {this.isActive(this.state.norm.active)}</span>
                    </div>
                </div>
                <div class="card-header-text-info">
                    <div className="card-header-text">
                        <span className="col-label">Organismo emisor: </span>
                        <span className="col-title">{this.state.norm.issuer}</span>
                    </div>
                    <div className="card-header-tags">
                        <div class="badge">
                            <span className="col-label">Alcance: </span>
                            <span className="col-title">{this.state.norm.scope}</span>
                        </div>

                        {this.renderRegion(this.state.norm.l2)}
                        {this.renderProvincia(this.state.norm.l3)}
                        {this.renderMunicipio(this.state.norm.l4)}
                    </div>
                </div>
                <div className="card-header-btns d-flex">
                    <DownloadPDFButton value={this.state.norm} index={this.state.index} />
                    <div><a className="btn btn-outline-secondary" data-bs-toggle="modal" data-bs-target={`#${BASE_MODAL_URL}${this.state.index}`} role="button"><i
                        className="d-flex mr-2"><img src="assets/images/icon-eye.svg" alt="icon-vermas" /></i> Ver
                        más</a></div>
                </div>
            </div>
            <div className="card-body">
                <h5 className="card-title">{this.state.norm.title}</h5>
                {this.renderTrimBody(this.state.norm.body)}
            </div>
        </div>
    }
}

export default Norm;