import axios from "axios";
import qs from 'qs';
import {WhmcsSettings} from "../Resources";

class WhmcsService {
    #auth = {
        identifier: WhmcsSettings.WHMCS_IDENTIFIER,
        secret: WhmcsSettings.WHMCS_SECRET
    };

    request(action, metadata) {
        const query = {
            ...this.#auth,
            ...metadata,
            action: action,
            responsetype: 'json'
        }

        const data = qs.stringify(query);

        return new Promise(((resolve, reject) => {
            axios({
                method: 'post',
                url: WhmcsSettings.URL_API,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                data: data,
            })
                .then(response => {
                    resolve({response})
                })
                .catch(error => {
                    reject(error)
                })
        }))
    }

    /*
    * valida que el cliente exista en la bd de whmcs
    * */
    validateLogin(userMail, password) {
        const metadata = {
            email: userMail,
            password2: password
        }
        return this.request('ValidateLogin', metadata);
    }

    getClients(metadata) {
        return this.request('GetClients', metadata)
    }

    getClientsProducts(clientId) {
        const metadata = {
            clientid: clientId,
        }
        return this.request('GetClientsProducts', metadata)
    }


    getTransactions(clientId) {
        const metadata = {
            clientid: clientId,
        }
        return this.request('GetTransactions', metadata)
    }

    createOAuthCredential(serviceId, description) {
        const metadata = {
            serviceid: serviceId,
            grantType: 'single_sign_on',
            scope: 'clientarea:sso clientarea:billing_info clientarea:announcements',
            description: description
        }
        return this.request('CreateOAuthCredential', metadata)
    }

    createSsoToken(serviceId, clientId,destination){
        const metadata={
            serviceId:serviceId,
            clientId:clientId,
            destination:destination
        }

        return this.request('CreateSsoToken',metadata);
    }



    /*
    * Esta request es bastante importante ya que nos proporciona el uuid correspondiente a openid
    * */
    getClientsDetails(clientId) {
        const metadata = {
            clientid: clientId,
        }
        return this.request('GetClientsDetails', metadata)
    }

    /*AcceptOrder:Accepts a pending order*/

    getAcceptOrder(orderId){
        const metadata = {
            orderid: orderId,
        }
        return this.request('AcceptOrder',metadata)
    }

    getAcceptQuote(quoteId){
        const metadata={
            quoteid:quoteId,
        }
        return this.request('AcceptQuote',metadata);
    }

    getActivateModule(moduleType,moduleName,parameters){
        const metadata={
            moduleType:moduleType,
            moduleName:moduleName,
            parameters:parameters,
        }
        return this.request('ActivateModule',metadata)
    }

    /*
    * fields example:
    * 'date' => '2016-01-01 15:30:00'
    * 'title' => 'Sample announcement',
    * 'announcement' => 'Text goes here...',
    * 'published' => '1',
    * */
    addAnnouncement(date,title,announcement,published){

    }


    AddBannedIp(ip,reason,days){
        const metadata={
            ip:ip,
            reason:reason,
            days:days,
        }
       return  this.request('AddBannedIp',metadata)
    }

    AddBillableItem(clientId,description,amount,invoiceaction,recur,recurcycle,recurfor,duedate){
        const metadata={
            clientid:clientId,
            description:description,
            amount:amount,
            invoiceaction:invoiceaction,
            recur:recur,
            recurcycle:recurcycle,
            recurfor:recurfor,
            duedate:duedate,
        }

        return this.request('AddBillableItem',metadata)
    }

    AddCancelRequest(action,serviceId,type){
        const metadata={
            action:action,
            serviceid:serviceId,
            type:type,
        }
        return this.request('AddCancelRequest',metadata)
    }


    //FIXME: Devuelve error, al parecer me faltan unos campos.
    // CustomFields son diferentes campos, revisar documentacion.
    AddClient(firstname,lastname,email,address1,city,state,postcode,phonenumber,password2,clientip,customFields){
        const metadata={
            firstname: firstname,
            lastname: lastname,
            email: email,
            address1: address1,
            city: city,
            state: state,
            postcode: postcode,
            country: phonenumber,
            phonenumber: password2,
            password2: "password",
            clientip: clientip,
            customfield:customFields
        }
        return this.request('AddClient',metadata)
    }

    AddClientNote(userid,notes,sticky){
        const metadata={
            userid:userid,
            notes:notes,
            sticky:sticky,
        }
        return this.request('AddClientNote',metadata)
    }

}

export default WhmcsService;
