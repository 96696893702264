import React from 'react';
import { Text, useTranslator } from "@eo-locale/react";

const FormControl = (props) => {

    const isHide = props.isHide;

    const style = {
        display: isHide ? "none" : "inline"
    }

    return (
        <div className="col-lg-4" style={style}>
            <div className="form-group mb-4">
                {props.children}
            </div>
        </div>
    )
}

const Label = (props) => {
    return <label {...props} />
}

const Input = (props) => {
    return <input type="text" className="form-control" {...props} />
}

const HiddeableInputForm = (props) => {
    const isHide = props.isHide;

    const translator = useTranslator();
    const {
        idFormControl,
        idTextLocalization,
        onChange,
    } = props;
    const placeholder = props.placeholder ? props.placeholder : '';
    return (
        <FormControl isHide={isHide}>
            <Label htmlFor={idFormControl}><Text id={idTextLocalization} /></Label>
            <Input
                placeholder={translator.translate(placeholder)}
                onChange={onChange} id={idFormControl} />
        </FormControl>
    );
};

export default HiddeableInputForm;