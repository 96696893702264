import React from 'react';

class ShowAdvanceFiltersButton extends React.Component {

    render() {
        return (
            <div className="col-lg-4 text-center text-md-left">
                <a onClick={this.props.onClick} href="#" className="text-link text-decoration-none" id="mostrar-filtros-avanzados">
                    {this.props.children}
                </a>
            </div>
        )
    }
}

export default ShowAdvanceFiltersButton;