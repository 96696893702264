import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import "../../Utils/react-datepicker.css"
import es from 'date-fns/locale/es';
import ReactTooltip from "react-tooltip";
import image from '../../Images/icon-info-warning.svg'
registerLocale('es', es)

class InputDatePicker extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            startDate: null,
            endDate: null,
        }

        this.handleStartChange = this.handleStartChange.bind(this);
        this.handleEndChange = this.handleEndChange.bind(this);
        this.convertDateOjectToElegDateFormat = this.convertDateOjectToElegDateFormat.bind(this);
    }

    handleStartChange(value) {
        if (!value) {
            this.props.onStartChange(this.setState({ startDate: value }))
        }
        else if (this.props && this.props.onStartChange && typeof this.props.onStartChange === 'function') {
            this.props.onStartChange(this.convertDateOjectToElegDateFormat(value))
        }
        this.setState({ startDate: value, endDate: "" })
    }

    handleEndChange(value) {
        if (!value) {
            this.props.onEndChange(this.setState({ endDate: "" }))
        }
        else if (this.props && this.props.onEndChange && typeof this.props.onEndChange === 'function') {
            this.props.onEndChange(this.convertDateOjectToElegDateFormat(value))
        }
        this.setState({ endDate: value })
    }

    convertDateOjectToElegDateFormat = (date) => new Date(date.getTime() - (date.getTimezoneOffset() * 60 * 1000)).toISOString().split('T')[0]

    addDays(date) {
        var result = new Date(date);
        const fechaSeleccionada = parseInt(this.convertDateOjectToElegDateFormat(date).replaceAll('-', ''));
        const diaActual = parseInt(this.convertDateOjectToElegDateFormat(new Date()).replaceAll('-', ''));
        if (this.convertDateOjectToElegDateFormat(date) === this.convertDateOjectToElegDateFormat(new Date())) {
            result.setDate(result.getDate() + 0);
        }
        if (fechaSeleccionada <= diaActual && fechaSeleccionada + 15 <= diaActual) {
            result.setDate(result.getDate() + 15);
        } else if (fechaSeleccionada - diaActual <= 15) {
            result.setDate(result.getDate() + (diaActual - fechaSeleccionada));
        }
        return result;
    }

    subDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() - days);
        return result;
    }

    render() {
        return (
            <div className={`col-lg-4 ${!this.props.disabled ? '' : 'content-blocked'}`} data-bs-toggle='modal' data-bs-target={`${!this.props.disabled ? '' : '#mejorarPlanModalFecha'}`} >
                <ReactTooltip id="dateTip" place="top" effect="solid" type="dark">
                    No debe haber más de 15 días de distancia entre ambos campos.
                </ReactTooltip>
                <div className='d-flex align-items-center'>
                    <label htmlFor={this.props.id}>{this.props.label} </label>
                    <img className="d-flex ml-2 mb-1" data-tip data-for="dateTip" alt="alerta" src={image} />
                </div>
                <div className='d-flex form-group'>
                    <DatePicker
                        locale="es"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.startDate}
                        onChange={this.handleStartChange}
                        selectsStart
                        isClearable
                        placeholderText="Desde"
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        maxDate={new Date()}
                        className='form-control'
                        disabled={this.props.disabled}
                    />
                    <DatePicker
                        locale="es"
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.endDate}
                        onChange={this.handleEndChange}
                        selectsEnd
                        isClearable
                        placeholderText="Hasta"
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        maxDate={this.addDays(this.state.startDate ? this.state.startDate : new Date(), 0)}
                        minDate={this.subDays(this.state.startDate, 0)}
                        className='form-control'
                        disabled={this.props.disabled ? this.props.disabled : this.state.startDate === null ? true : false}
                    />
                </div>
            </div>
        )
    }


}
export default InputDatePicker;