import React from 'react';
import { getLevel3 } from '../../Services/ElegService';
import InputLevel from './InputLevel';

class InputLevel3 extends React.Component {
    render() {
        return (
            <div className="col-lg-4">
                <div className="form-group mb-4">
                    <InputLevel {...this.props} id="level3" callback={(value)=>getLevel3(this.props.p1, value)} bar={2} />
                </div>
            </div>
        )
    }
}

export default InputLevel3;
