import React from 'react';
import InputCountry from './InputCountry';
import InputNormType from './InputNormType';
import InputNroNorma from './InputNroNorma';
import InputScope from './InputScope';
import InputYear from './InputYear';
import InputLevel2 from './InputLevel2';
import InputLevel3 from './InputLevel3';
import InputLevel4 from './InputLevel4';
import InputPalabras from './InputPalabras';
import ShowAdvanceFiltersButton from './ShowAdvanceFiltersButton';
import SearchButton from './SearchButton';
import Form from './Form';
import Row from './Row';
import Center from './Center';
import NextLine from './NextLine';
import Search from './Search';
import CleanSearch from './CleanSearch';
import WarningMessage from './WarningMessage';
import InputIssuer from './InputIssuer';
import InputDatePicker from './InputDatePicker';

const Wrapping = (props) => {
    return <section className="resultSet pb-5">
        <div className="container">
            <div className="row" {...props} />
        </div>
    </section>
}


class SearchView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            token: props.token,
            email: props.userEmail,
            country: "",
            normType: "",
            year: "",
            nroNorma: "",
            scope: "",
            level2: "",
            level3: "",
            level4: "",
            palabras: "",
            scopeLevel: 0,
            showAdvanceFilters: false,
            previousQuery: null,
            warning: false,
            typeWarning: false,
            issuer: "",
            startDate: "",
            endDate: "",
        }

        this.switchAdvanceFilters = this.switchAdvanceFilters.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateQuery = this.validateQuery.bind(this);
        this.parsedNroNorm = this.parsedNroNorm.bind(this);
    }

    switchAdvanceFilters(event) {
        event.preventDefault();
        this.setState((prevState) => ({
            showAdvanceFilters: !prevState.showAdvanceFilters
        }));
    }

    validateQuery() {
        // Siempre tiene que tener country y una de las siguientes opciones:
        // "Tipo de Norma" o "Fecha" o "Busqueda por palabra (BxP)"
        // Pais Tipo Fecha  BxP
        //    0    -    -    -  Fracaso --> Que se tiene que marcar aca
        //    1    0    -    0  Fracaso --> Que se marque "a definir con Dani" ??
        //                              Arriba que aparezca un cartel que diga error:
        //                                  Para realizar una consulta debes seleccionar minimamente el `tipo` o `fecha` o `busqueda por palabra`.

        let countryValue = this.state.country;
        let normTypeValue = this.state.normType;
        let yearValue = this.state.year;
        let nroNormValue = this.state.nroNorma;
        let searchWithWords = this.state.palabras;
        let startDate = this.state.startDate;
        let endDate = this.state.endDate;

        let res = {
            premium: this.props.userPlan === 'active',
            valid: false,
            hasNormType: !!normTypeValue,
            hasDate: searchWithWords.length >= 4,
            hasSearch: startDate && endDate,
            hasYear: !!yearValue,
            hasNroNorm: !!nroNormValue
        };

        if (!res.premium) {
            if(!normTypeValue || (!nroNormValue && !yearValue)) {
                return res;
            }
        }

        if (countryValue && (res.hasSearch || res.hasDate || res.hasNormType)) {
            res.valid = true;
        }

        return res;
    }

    parsedNroNorm(value) {
        if (value !== null) {
            const parsedNroNorm = value.split('/')[0].replace(' ');
            this.setState({ nroNorma: parsedNroNorm })
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        const res = this.validateQuery();
        if (res.valid) {
            const sl = this.state.scopeLevel;
            if (sl === 3) {
                this.setState({ previousQuery: { ...this.state, level4: "" }, warning: false, typeWarning: !res.hasNormType, level4: "" })
            } else if (sl === 2) {
                this.setState({ previousQuery: { ...this.state, level4: "", level3: "" }, warning: false, typeWarning: !res.hasNormType, level4: "", level3: "" })
            } else if (sl === 1) {
                this.setState({ previousQuery: { ...this.state, level4: "", level3: "", level2: "" }, warning: false, typeWarning: !res.hasNormType, level4: "", level3: "", level2: "" })
            } else {
                this.setState({ previousQuery: { ...this.state }, warning: false, typeWarning: !res.hasNormType })
            }
        } else {
            this.setState({ premium: res.premium, warning: true, typeWarning: !res.hasNormType, dateWaring: !res.hasDate, searchWarning: !res.hasSearch })
        }
    }

    clearAction = (event) => {
        event.preventDefault();
        window.location.reload();
    }

    render() {
        return <>
            <Form>
                {(this.state.warning && this.state.premium) ? <WarningMessage text={"Recuerde que puede buscar por tipo de norma, búsqueda por palabra o fecha. Por favor complete alguno de estos filtros."} /> : <></>}
                {(this.state.warning && !this.state.premium) ? <WarningMessage text={"Por favor, seleccione tipo de norma, año y/o número de norma para realizar la búsqueda."} /> : <></>}
                {(this.state.warning && !this.state.typeWarning) ? <WarningMessage warning={true} text={"Por favor, seleccione `Año` y/ó `Nº de Norma` para usuarios free"} /> : <></>}
                <Row>
                    <InputCountry foo={(value) => this.setState({ country: value })} />
                    <InputNormType foo={(value) => this.setState({ normType: value })} bar={this.state.country} warning={this.state.warning} required={true} premium={this.props.userPlan === 'active'} />
                    <InputYear foo={(value) => this.setState({ year: value })} required={false} premium={this.props.userPlan === 'active'} text={"Es condición obligatoria seleccionar `Año` ó `Nº de Norma` para usuarios free"} id="inputYear" tooltip={false} />
                    <InputNroNorma foo={(value) => this.parsedNroNorm(value)} required={false} premium={this.props.userPlan === 'active'} text={"Es condición obligatoria seleccionar `Año` ó `Nº de Norma` para usuarios free"} id="inputNro" tooltip={false} />
                    <NextLine />
                    <InputScope premium={this.props.userPlan === 'active'} foo={(scope, level) => this.setState({ scope: scope, scopeLevel: level })} bar={this.state.country} />
                    {(this.state.scopeLevel > 1) ?
                        <InputLevel2 onChange={(value) => this.setState({ level2: value })} updateOn={this.state.country} foo={this.state.country} /> :
                        <></>}
                    {(this.state.scopeLevel > 2) ?
                        <InputLevel3 onChange={(value) => this.setState({ level3: value })} updateOn={this.state.level2} foo={this.state.country} p1={this.state.country} /> :
                        <></>}
                    {(this.state.scopeLevel > 3) ?
                        <InputLevel4 onChange={(value) => this.setState({ level4: value })} updateOn={this.state.level3} foo={this.state.country} p1={this.state.country} p2={this.state.level2} /> :
                        <></>}
                    <NextLine />
                    <InputIssuer premium={this.props.userPlan === 'active'} foo={(value) => this.setState({ issuer: value })} bar={this.state.country} updateOn={{ scopeLevel: this.state.scopeLevel, country: this.state.country, level2: this.state.level2, level3: this.state.level3, level4: this.state.level4 }} />
                    <InputPalabras disabled={this.props.userPlan !== 'active'} foo={(value) => this.setState({ palabras: value })} tooltip={true} text={"Para poder buscar solamente con palabras es necesario un minimo de 4 caracteres"} premium={this.props.userPlan === 'active'} />
                    <InputDatePicker disabled={this.props.userPlan !== 'active'} id="datePicker" label="Fecha" onStartChange={(value) => this.setState({ startDate: value })} onEndChange={(value) => this.setState({ endDate: value })} />
                    <NextLine />
                    <ShowAdvanceFiltersButton onClick={this.switchAdvanceFilters}>{this.state.showAdvanceFilters ? "" : ""}</ShowAdvanceFiltersButton>
                </Row>
                <Center>
                    <SearchButton onClick={this.handleSubmit} />
                    <CleanSearch onClick={this.clearAction} />
                </Center>
            </Form>
            <Wrapping>
                <Search query={this.state.previousQuery} veryLastShortcut veryFirstShortcut premium={this.props.userPlan === 'active'} />
            </Wrapping>

        </>
    }
}

export default SearchView;
