import React from 'react';
import ReactTooltip from "react-tooltip";
import image from '../../Images/icon-info-warning.svg'

class InputText extends React.Component {

    constructor(props) {
        super(props);
        this.parentSetter = props.foo;
        this.state = {
            value: props.defaultValue || "",
            warning: props.warning,
            required: props.required,
            premium: props.premium,
            idModal: props.idModal,
        }

        this.handleChange = this.handleChange.bind(this);
        this.renderInputText = this.renderInputText.bind(this);
    }

    handleChange(event) {
        this.setState({ value: event.target.value });
        if (this.parentSetter) {
            this.parentSetter(event.target.value);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.warning !== prevProps.warning) {
            this.setState({
                warning: this.props.warning
            })
        }
    }

    renderInputText() {
        return (
            <input type="text"
                className={`form-control ${this.state.warning ? 'border-warning' : ''} `} placeholder={this.props.placeholder} id={this.props.id}
                value={this.state.value} onChange={this.handleChange}></input>
        )
    }

    render() {
        return (
            <>
                {this.props.disabled ?
                    <div className={`${this.props.premium ? '' : 'content-blocked'}`} data-bs-toggle='modal' data-bs-target={this.state.idModal} >
                        <div className='d-flex align-items-center'>
                            <label htmlFor={this.props.id}>{this.props.label} </label>
                        </div>
                        <input type="text" disabled
                            className={`form-control ${this.state.warning ? 'border-warning' : ''} ${this.state.required && !this.props.premium ? 'is-required' : ''}`} placeholder={this.props.placeholder} id={this.props.id}
                            value={this.state.value} onChange={this.handleChange}></input>
                    </div>
                    :
                    this.props.tooltip
                        ?
                        < div className={`${this.props.premium ? '' : 'content-blocked'}`} data-bs-toggle='modal' data-bs-target={this.props.premium ? '' : this.state.idModal}>
                            <ReactTooltip id={this.props.id} place="top" effect="solid" type="dark">
                                {this.props.text}
                            </ReactTooltip>
                            <div className='d-flex align-items-center'>
                                <label htmlFor={this.props.id}>{this.props.label} </label>
                                <img className="d-flex ml-2 mb-1" data-tip data-for={this.props.id} alt="alerta" src={image} />
                            </div>
                            {this.renderInputText()}
                        </div> :
                        <>
                            <div className='d-flex align-items-center'>
                                <label htmlFor={this.props.id}>{this.props.label} </label>
                            </div>
                            {this.renderInputText()}
                        </>
                }
            </>
        )
    }
}

export default InputText;