import React from 'react';
import { getScope } from '../../Services/ElegService';

const DisabledSelect = (props) => {
    return <select disabled {...props} />
}

const EnabledSelect = (props) => {
    return <select {...props} />
}

const emptyItem = {
    key: "0",
    label: "",
    text: "",
    value: "",
}

class InputScope extends React.Component {

    constructor(props) {
        super(props);
        this.parentSetter = props.foo;
        this.state = {
            value: "",
            options: [
                { key: "Cargando", value: "Cargando" }
            ],
            enabled: false,

            arguments: "",
        }
        this.modalRef = React.createRef();
        this._handleChange = this._handleChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    _handleChange(value, index) {
        this.setState({ value: value });
        if (this.parentSetter) {
            this.parentSetter(value, index);
        }
    }

    handleChange(event) {
        if (!this.props.premium && event.target.selectedIndex > 2) {
            this.modalRef.current.click()
            this._handleChange(this.state.options[0].value, 0);
        } else {
            this._handleChange(event.target.value, event.target.selectedIndex);
        }
    }

    componentDidMount() {
        const args = this.props.bar;
        if (args) {
            const p = getScope(args);
            p.then(res => {
                //Agrego un campo vacio
                res.unshift(emptyItem);

                this.setState({
                    options: res,
                    enabled: true
                })
                this._handleChange(res[0].value, 0);
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.bar !== prevProps.bar) {
            this.setState({
                enabled: false
            })
            const p = getScope(this.props.bar);
            p.then(res => {
                //Agrego un campo vacio
                res.unshift(emptyItem);

                this.setState({
                    options: res,
                    enabled: true
                })
                this._handleChange(res[0].value, 0);
            })
        }
    }

    render() {

        const selectProps = {
            id: "scope",
            className: "custom-select",
            value: this.state.value,
            onChange: this.handleChange,
            children: this.state.options.map(function (item, index) {
                let optionProps = {
                    key: item.key,
                    value: item.value,
                    children: item.value,
                };
                return <option  {...optionProps} />
            })
        }

        return (
            <>
                <div className="col-lg-4">
                    <div className="form-group mb-4">
                        {this.props.premium
                            ? <label>Alcance</label>
                            : <label htmlFor="scope" data-bs-toggle='modal' data-bs-target={`#mejorarPlanModalInputScope`} ref={this.modalRef}>Alcance </label>}

                        {this.state.enabled ? <EnabledSelect {...selectProps} /> : <DisabledSelect {...selectProps} />}
                    </div>
                </div>
            </>
        )
    }
}

export default InputScope;
