import React from 'react';
import {Text} from '@eo-locale/react';
import {HiOutlineDownload} from 'react-icons/hi'
import {AiOutlineEye} from 'react-icons/ai'
import {TriggerModal, Chip} from "./index";
import {BuildPdf} from "../Utils";

const useDisclosure = () => {
    return {
        isOpen: true.valueOf,
        onOpen: () => { },
        onClose: () => { },
    }
}

const Button = (props) => {
    return <div {...props} />
}

const Link = (props) => {
    return <a {...props} />
}

const Box = (props) => {
    return <div {...props} />
}

const ModalBody = (props) => {
    return <div {...props} />
}

const ModalCloseButton = (props) => {
    return <div {...props} />
}

const ModalContent = (props) => {
    return <div {...props} />
}

const ModalFooter = (props) => {
    return <div {...props} />
}

const ModalHeader = (props) => {
    return <div {...props} />
}

const ModalOverlay = (props) => {
    return <div {...props} />
}

const Grid = (props) => {
    return <div {...props} />
}

const TextChakra = (props) => {
    return <p {...props} />
}

const ResultCard = (props) => {
    const {onOpen, isOpen, onClose} = useDisclosure()
    const {property} = props;
    const modal = () => {
        return (
            <TriggerModal
                isOpen={isOpen}
                onClose={onClose}
                norm={property.norma}
                issuingAgency={property.organismoEmisor}
                scope={property.alcance}
                text={property.content}
                title={property.titulo}
            />
        )
    }

    return (
        <Box
            width={"80%"}
            borderWidth="1px"
            borderRadius="lg"
            marginTop={4}
        >
            <Grid
                templateColumns={'repeat(2, 100fr)'}
                spacing="20px"
            >
                <Box
                    d={"flex"}
                    flexDirection={{md:"row",base:'column'}}
                >
                    <Chip text={property.norma} idTraslate={'Norm'}/>
                    <Chip text={property.organismoEmisor} idTraslate={'Issuing Organization'}/>
                    <Chip text={property.alcance} idTraslate={'Scope'}/>
                </Box>
                <Box
                    height={{md:"2rem",base:"50%"}}
                    width={{md:"20rem",base:'none'}}
                    d={"flex"}
                    alignItems={"center"}
                    justifyItems={"center"}
                    justifySelf={{md: "flex-end",base:'center'}}
                    margin={"1rem"}
                    flexDirection={{md:'row',base:'column'}}
                >
                    <Button
                        w={{md: "15rem",base:'full'}}
                        size="xs"
                        leftIcon={<HiOutlineDownload/>}
                        marginRight={{md: "1rem"}}
                        marginTop={{base:"1rem"}}
                        onClick={() => BuildPdf(property.norma, property.organismoEmisor, property.alcance, property.content, property.titulo)}
                    >
                        <Text id={'Download PDF'}/>
                    </Button>
                    <Button
                        w={{md: "none",base:'full'}}
                        size="xs"
                        leftIcon={<AiOutlineEye/>}
                        onClick={onOpen}
                        marginTop={{base:"1rem"}}
                    >
                        <Text id={'See more'}/>
                    </Button>
                    {modal()}
                </Box>
            </Grid>
            <TextChakra
                margin={3}
                as={"h2"}
                size="lg"
            >
                {property.titulo}
            </TextChakra>
            <TextChakra
                margin={3}
                fontSize={"13px"}
                noOfLines={2}
            >
                {property.content}
            </TextChakra>
        </Box>
    );
};

export default ResultCard;
