const TrailerBanner = (props) => {
    return (
        <section className="block-dark block-dark-02">
            <div className="background text-center text-md-left">
                <div className="offset-line mb-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="section-title">Confiabilidad</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 pb-5 pb-md-0">
                            <h3 className="mb-3">Seguridad informática</h3>
                            <p className="mb-4 pb-2">La seguridad del sitio está avalada por SECTIGO RSA.</p>
                            <div className="mt-4">
                                <img src="https://eleg.app/themes/elegsquad/assets/images/logo-sectigo.png" alt="logo-sectigo" />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <h3 className="mb-3">ISO 9001</h3>
                            <p>Este producto se encuentra <a href="https://eleg.app/storage/app/media/Certificado-2022.pdf" target="blank">certificado ISO 9001:2015</a></p>
                            <div>
                                <a href="https://eleg.app/storage/app/media/Certificado-2022.pdf" target="blank">
                                    <img src="https://eleg.app/themes/elegsquad/assets/images/logo-iso-9001.png" alt="logo-9001" /></a>
                                <img src="https://eleg.app/themes/elegsquad/assets/images/logo-tuvrheinland.png" alt="logo-tuvrheinland" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TrailerBanner;