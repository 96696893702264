import React from 'react';
import { BeatLoader } from "react-spinners";
// import {Center, css} from "@chakra-ui/react";

const Center = (props) => {
    return <div {...props} />
}

// const override = css`
//   display: block;
//   margin: 40vh auto;
//   border-color: red;
// `;

const Loading = () => {
    let loading = true;
    let color = "#D6C851";
    return (
        <Center>
            <BeatLoader
                // css={override}
                size={"5rem"} loading={loading} color={color} />
        </Center>
    );
};

export default Loading;
