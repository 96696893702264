import React from 'react';
import ModalEnhancementPlan from './ModalEnhancementPlan';

const Wrapping = (props) => {
    return <nav className="col-12 pt-4 pb-5">
        <ul className="pagination justify-content-center" {...props} />
    </nav>
}

class Navigation extends React.Component {
    constructor(props) {
        super(props);

        const pc = props.pagesLength || 1;
        const cp = props.currentPage || 0;

        const max = props.maxPages || 0;
        const loading = props.loading || false;

        const scope = (!isNaN(props.scope) && props.scope >= 0) ? props.scope : 2

        this.state = {
            pagesLength: pc,
            currentPage: cp,
            prevEnabled: cp > 0,
            nextEnabled: cp < pc - 1,
            scope: scope,
            max: max,
            loading: loading,
        }

        this.handleChange = this.handleChange.bind(this);
        this.drawPreviousNavigation = this.drawPreviousNavigation.bind(this);
        this.drawNextNavigation = this.drawNextNavigation.bind(this);
        this.drawPageSelector = this.drawPageSelector.bind(this);
    }

    handleChange(event, page) {
        event.preventDefault();
        this.setState({ currentPage: page })
        if (this.props.onChange) {
            this.props.onChange(page);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.loading !== this.props.loading) {
            this.setState({ loading: this.props.loading })
        }
        if ((prevProps.currentPage !== this.props.currentPage) || (prevProps.pagesLength !== this.props.pagesLength)) {
            this.setState({
                currentPage: this.props.currentPage,
                pagesLength: this.props.pagesLength,
            });
        }
        if ((prevState.currentPage !== this.state.currentPage) || (prevState.pagesLength !== this.state.pagesLength)) {

            const pc = this.state.pagesLength;
            const cp = this.state.currentPage || 0;

            this.setState({
                prevEnabled: cp > 0,
                nextEnabled: cp < pc - 1,
            });
        }
    }

    drawPreviousNavigation = () => {
        let bootstrapClasses = ["btn", "page-link", "border-0"];
        if (!this.state.prevEnabled || this.state.loading) {
            bootstrapClasses.push("disabled");
        }
        const cn = bootstrapClasses.join(" ");

        return <>
            {this.props.premium ?
                <>
                    {this.props.veryFirstShortcut ? <li className="page-item mx-0"><a className={cn} href="#" onClick={e => this.handleChange(e, 0)}><img src="assets/images/icon-chevron-double-left.svg" /></a></li> : <></>}
                    < li className="page-item mr-4"><a className={cn} href="#" onClick={e => this.handleChange(e, this.state.currentPage - 1)}><img src="assets/images/icon-chevron-left.svg" /></a></li>
                </> : <>
                    <li className="page-item mx-0"><a className={cn} data-bs-toggle='modal' data-bs-target={`#mejorarPlanModalNavigation`}><img src="assets/images/icon-chevron-double-left.svg" /></a></li>
                    < li className="page-item mr-4"><a className={cn} data-bs-toggle='modal' data-bs-target={`#mejorarPlanModalNavigation`}><img src="assets/images/icon-chevron-left.svg" /></a></li>
                </>
            }
        </>
    }

    drawNextNavigation = () => {
        let bootstrapClasses = ["btn", "page-link", "border-0"];
        if (!this.state.nextEnabled || this.state.loading) {
            bootstrapClasses.push("disabled");
        }
        const cn = bootstrapClasses.join(" ");

        return <>
            {this.props.premium ?
                <>
                    <li className="page-item ml-4"><a className={cn} href="#" onClick={e => this.handleChange(e, this.state.currentPage + 1)}><img src="assets/images/icon-chevron-right.svg" /></a></li>
                    {this.props.veryLastShortcut ? <li className="page-item mx-0"><a className={cn} href="#" onClick={e => this.handleChange(e, this.state.pagesLength - 1)}><img src="assets/images/icon-chevron-double-right.svg" /></a></li> : <></>}
                </>
                :
                <>
                    <li className="page-item ml-4"><a className={cn} data-bs-toggle='modal' data-bs-target={`#mejorarPlanModalNavigation`}><img src="assets/images/icon-chevron-right.svg" /></a></li>
                    {this.props.veryLastShortcut ? <li className="page-item mx-0"><a className={cn} data-bs-toggle='modal' data-bs-target={`#mejorarPlanModalNavigation`} ><img src="assets/images/icon-chevron-double-right.svg" /></a></li> : <></>}
                </>
            }
        </>
    }

    drawPageSelector = () => {

        const drawFirst = !this.props.veryFirstShortcut;
        const drawLast = !this.props.veryLastShortcut;
        const scope = this.state.scope;
        const currentPage = this.state.currentPage;
        const pagesLength = this.state.pagesLength;
        const max = this.state.max;

        const allPages = [...Array(pagesLength).keys()];
        const limitSearch = max ? allPages.map((x) => (x < max) ? x : -x) : allPages;
        const obfuscatedPages = limitSearch.map((x) => {
            if (drawFirst && (x === 0)) return x;
            if (drawLast && (x === pagesLength - 1)) return x;
            if (x === currentPage) return x;
            if (x > currentPage) {
                if (x <= (currentPage + scope)) return x;
            } else {
                if (x >= (currentPage - scope)) return x;
            }
            return "...";
        });

        const reducedPages = obfuscatedPages.filter((value, index, array) => !(index !== 0 && value === "..." && array[index - 1] === "..."));

        const drawButton = (x) => {
            return <>
                {this.props.premium ?
                    <li key={"a" + x} className="page-item d-none d-md-flex"><a className="btn page-link" href="#" onClick={e => this.handleChange(e, x)}>{x + 1}</a></li>
                    :
                    <li key={"a" + x} className="page-item d-none d-md-flex"><a className="btn page-link" href="#" data-bs-toggle='modal' data-bs-target={`#mejorarPlanModalNavigation`} >{x + 1}</a></li>
                }
            </>
        }

        const drawActiveButton = (x) => {
            return <>
                {this.props.premium ?
                    <li key={"b" + x} className="page-item active"><a className="btn page-link" href="#" onClick={e => e.preventDefault()}>{x + 1}</a></li>
                    :
                    <li key={"b" + x} className="page-item active"><a className="btn page-link" href="#" data-bs-toggle='modal' data-bs-target={`#mejorarPlanModalNavigation`}>{x + 1}</a></li>
                }
            </>
        }

        const drawSeparator = (x) => {
            return <>
                {this.props.premium ?
                    <li key={"c" + x} className="page-item d-none d-md-flex"><a className="btn page-link border-0 disabled" href="#" onClick={e => e.preventDefault()}>...</a></li>
                    :
                    <li key={"c" + x} className="page-item d-none d-md-flex"><a className="btn page-link border-0 disabled" href="#" data-bs-toggle='modal' data-bs-target={`#mejorarPlanModalNavigation`}>...</a></li>
                }
            </>
        }

        const drawDisabledButton = (x) => {
            return <>
                {this.props.premium ?
                    <li key={"d" + x} className="page-item d-none d-md-flex"><a className="btn page-link disabled" href="#" onClick={e => this.handleChange(e, x)}>{x + 1}</a></li>
                    :
                    <li key={"d" + x} className="page-item d-none d-md-flex"><a className="btn page-link disabled" href="#" data-bs-toggle='modal' data-bs-target={`#mejorarPlanModalNavigation`}>{x + 1}</a></li>
                }
            </>
        }

        return <>
            {reducedPages.map((x) => {
                if (isNaN(x)) {
                    return drawSeparator(x);
                }
                if (x === currentPage) {
                    return drawActiveButton(x);
                }
                if (x < 0 || this.state.loading) {
                    return drawDisabledButton(-x);
                }
                return drawButton(x);
            })
            }
        </>
    }

    render() {
        return <Wrapping>
            <ModalEnhancementPlan id={'mejorarPlanModalNavigation'} text='El Plan Básico solo puede visualizar hasta 10 resultados por búsqueda' />
            {this.drawPreviousNavigation()}
            {this.drawPageSelector()}
            {this.drawNextNavigation()}
        </Wrapping>
    }
}

export default Navigation;