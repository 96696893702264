import React from 'react';
import Navigation from './Navigation';
import SearchResults from './SearchResults';
import { getNormas } from '../../Services/ElegService';
import ModalEnhancementPlan from './ModalEnhancementPlan';
import { scroller } from 'react-scroll';

const validateQuery = (query) => {
    return true;
}

const bar = (x, pageSize, page) => {
    return {
        Pais: x.country,
        Tipo: x.normType,
        Anho: x.year,
        Numero: x.nroNorma,
        Alcance: x.scope,
        Level2: x.scopeLevel > 0 ? x.level2 : undefined,
        Level3: x.scopeLevel > 1 ? x.level3 : undefined,
        Level4: x.scopeLevel > 2 ? x.level4 : undefined,
        Organismo: (x.issuer && x.issuer !== "0") ? x.issuer : undefined,
        Texto: x.palabras,
        Desde: x.startDate,
        Hasta: x.endDate,
        Pagina: page || 0,
    }
}

const bar2 = (x) => {
    return {
        Token: x.token,
        Mail: x.email
    }
}

const foo = (a) => {
    return a.list.map((x) => {
        return {
            id: x.id,
            type: x.tipo,
            number: x.numero,
            year: x.anho,
            issuer: x.organismoEmisor,
            scope: x.alcance,
            l2: x.region,
            l3: x.provincia,
            l4: x.municipio,
            body: x.content,
            date: x.fecha,
            hasFile: x.archivo,
            active: x.vigente,
            name: x.norma,
            text: x.texto
            // title: x.titulo,
        }
    })
}

class Search extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            query: props.query,
            results: [],
            loading: false,
            normRequest: null,
            currentPage: 0,
            pages: 1,
            error: false,
        }
        this.modalRef = React.createRef();

        this.renderResults = this.renderResults.bind(this);
        this.renderNoResults = this.renderNoResults.bind(this);
        this.renderLoading = this.renderLoading.bind(this);
        this.renderError = this.renderError.bind(this);
        this.renderModalEnhancement = this.renderModalEnhancement.bind(this);
        this.doNotRender = this.doNotRender.bind(this);
        this.handleNavigationInput = this.handleNavigationInput.bind(this);
        this.scrollToSeachButton = this.scrollToSearchButton.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.query !== prevProps.query) {
            if (validateQuery(this.props.query)) {
                const normRequest = bar(this.props.query);
                const requestHeader = bar2(this.props.query);
                this.setState({
                    query: this.props.query,
                    normRequest: normRequest,
                    requestHeader: requestHeader,
                    currentPage: 0,
                    pages: 1,
                });
            }
        }
        if (this.state.normRequest !== prevState.normRequest) {
            this.setState({
                loading: true,
            })
            getNormas(this.state.normRequest, this.state.requestHeader).then((x) => {
                const pages = Math.ceil(x.count / x.pageSize) || 1;
                const results = foo(x);
                this.setState({
                    results: results,
                    pages: pages,
                    loading: false,
                    error: false,
                })
                this.scrollToSearchButton();
            }).catch(e => {
                this.setState({
                    loading: false,
                    error: true,
                });
                this.scrollToSearchButton();
                this.modalRef.current.click()
            })
        }
    }

    handleNavigationInput(page) {
        this.setState((prevState) => ({
            normRequest: {
                ...prevState.normRequest,
                Pagina: page,
                loading: true,
            },
            currentPage: page,
        }));
    }

    scrollToSearchButton() {
        scroller.scrollTo("SearchButton", {
            duration: 1000,
            delay: 100,
            smooth: true,
            offset: -200,
        });
    }

    renderResults() {
        return <>
            <SearchResults results={this.state.results} />
            <Navigation
                onChange={this.handleNavigationInput} loading={this.state.loading}
                currentPage={this.state.currentPage} pagesLength={this.state.pages}
                veryFirstShortcut={this.props.veryFirstShortcut} veryLastShortcut={this.props.veryLastShortcut}
                premium={this.props.premium}
            />
        </>
    }

    renderNoResults() {
        return <>
            <div className='container'>
                <div className='card'>
                    <div className='card-body'>
                        <h5 className='card-title'>Intentar nueva búsqueda.</h5>
                        <p className='mb-0'>No se encontraron resultados para los filtros aplicados.</p>
                    </div>
                </div>
            </div>
        </>
    }

    renderLoading() {
        return <>
            <div className="spinner-bg">
                <div className="spinner-grow" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </>
    }

    renderError() {
        return <>
            <div className='container'>
                <div className='card'>
                    <div className='card-body'>
                        <p className='mb-0' >No pudimos obtener resultados, por favor verifica de ser usuario gratuito de no haber superado tu limite diario de busquedas, en cualquier otro caso comproba tu conexion a internet.</p>
                    </div>
                </div>
            </div>
        </>
    }

    renderModalEnhancement() {
        return <div>
            <ModalEnhancementPlan id={'mejorarPlanModalInputScope'} text='La búsqueda a nivel Municipal es una caractéristica para usuarios con Plan Avanzado' />
            <ModalEnhancementPlan id={'mejorarPlanModalOrganismo'} text='La búsqueda por Organismo Emisor es una característica para usuarios con Plan Avanzado' />
            <ModalEnhancementPlan id={'mejorarPlanModalBusquedaPalabras'} text='La búsqueda por palabras es una característica para usuarios con Plan Avanzado' />
            <ModalEnhancementPlan id={'mejorarPlanModalFecha'} text='La búsqueda por rango de fecha es una característica para usuarios con Plan Avanzado' />
            <ModalEnhancementPlan id={'mejorarPlanModalSearch'} text='Alcanzada la cantidad de búsquedas permitidas en el Plan Básico' />
        </div>

    }

    doNotRender() {
        return <></>;
    }

    r() {
        if (this.state.query) {
            if (this.state.loading) {
                return this.renderLoading();
            }
            if (this.state.error) {
                return this.renderError();
            }
            if (this.state.results.length <= 0) {
                return this.renderNoResults();
            }
            return this.renderResults();
        } else {
            return this.doNotRender();
        }
    }

    render() {
        return <>
            {this.renderModalEnhancement()}
            <label className='d-none' data-bs-toggle='modal' data-bs-target={`#mejorarPlanModalSearch`} ref={this.modalRef} >LABEL</label>
            {this.r()}
        </>
    }
}

export default Search;
