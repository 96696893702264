import React from 'react';

const URL_PDF_REPOSITORY = process.env.REACT_APP_URL_PDF_REPOSITORY;

class DownloadPDFButton extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            norm: props.value || {},
            index: props.index || 0,
        }
    }

    render() {
        if (this.state.norm && this.state.norm.hasFile && this.state.norm.id) {
            return <div className="mr-2"><a className="btn btn-outline-secondary" href={`${URL_PDF_REPOSITORY}/pdf?id=${this.state.norm.id}`}>
                <i className="d-flex mr-2"><img src="assets/images/icon-download.svg" alt="icon-download" /></i> Descargar PDF</a>
            </div>
        } else {
            return <></>
        }
    }
}

export default DownloadPDFButton;