import SV from './SearchView';
import UpperBanner from './UpperBanner';
import { userContext } from '../../App';
import TrailerBanner from './TrailerBanner';
import { useContext } from 'react';

const SearchView = (props) => {
    const uc = useContext(userContext);
    return <>
        <UpperBanner />
        <SV token={uc.user.id_token} userEmail={uc.user.profile.email} userPlan={uc.plan} />
        <TrailerBanner />
    </>
}

export default SearchView