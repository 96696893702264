import React, { useState } from 'react'
import ReactTooltip from "react-tooltip"

const Header = () => {
    const [modalOpener, setModalOpener] = useState(false);

    const handlerSignOut = (e) => {
        e.preventDefault()
        localStorage.removeItem('login')
        localStorage.removeItem('user')
        localStorage.removeItem('redirectCallback')
        window.location.replace(process.env.REACT_APP_LINK_TO_LOGOUT);
    }

    const openModal = (e) => {
        if (e.target) {
            setModalOpener(!modalOpener)
        }
    }
    
    return (
        <div className="site-nav d-flex flex-column flex-md-row align-items-center p-2 px-md-4 mb-n1 bg-white border-bottom shadow-sm">
            <nav className="navbar navbar-expand-md navbar-light w-100">
                <a className="navbar-brand" href="http://eleg.app/"><img src="assets/images/logo.svg" alt="logo-eleg" /></a>
                <button className="navbar-toggler dropdown-toggle " role="button" data-bs-toggle="dropdown" id="navbarsHeader"
                    aria-expanded="false">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <ul className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarsHeader">
                    <li className="nav-item py-2 py-md-0">
                        <a className="nav-link" href="https://eleg.app/quienes-somos">¿Quienes somos?</a>
                    </li>
                    <li className="nav-item py-2 py-md-0">
                        <a className="nav-link" href="https://eleg.app/#nuestros-productos">Nuestros Productos</a>
                    </li>
                    <li className="nav-item py-2 py-md-0">
                        <a className="nav-link" href="https://eleg.app/novedades">Novedades</a>
                    </li>
                    <li className="nav-item py-2 py-md-0">
                        <a className="nav-link mx-auto" onClick={handlerSignOut} href="https://clientes.eleg.app/">Ir a perfil</a>
                    </li>
                    <li className="nav-item py-2 py-md-0 pl-3">
                        <a href="https://eleg.app/contacto" className="" data-toggle="tooltip" data-placement="top" title="" data-original-title="Contacto">
                            <i className="fas fa-envelope" />
                            <span className='pl-2'>Contacto</span>
                        </a>
                    </li>
                    <li className="nav-item py-2 py-md-0 pt-1">
                        <a href="https://estrucplan.com.ar/" className="dropdown-item text-uppercase">
                            <small>Estrucplan Consultora</small>
                        </a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item" href="https://estrucplan.com.ar/home-medio-ambiente/">Medio Ambiente</a>
                        <a className="dropdown-item" href="https://estrucplan.com.ar/home-seg/">Seguridad e Higiene</a>
                        <a className="dropdown-item" href="https://estrucplan.com.ar/elab/">Laboratorio</a>
                        <a className="dropdown-item" href="https://eleg.app/">Legislación</a>
                    </li>
                </ul>
                <div className="navbar-collapse collapse">
                    <ul className="navbar-nav text-center ml-auto py-5 py-md-0">
                        <li className="nav-item py-2 py-md-0">
                            <a className="nav-link" href="https://eleg.app/quienes-somos">¿Quienes somos?</a>
                        </li>
                        <li className="nav-item py-2 py-md-0">
                            <a className="nav-link" href="https://eleg.app/#nuestros-productos">Nuestros Productos</a>
                        </li>
                        <li className="nav-item py-2 py-md-0">
                            <a className="nav-link" href="https://eleg.app/novedades">Novedades</a>
                        </li>
                        <li className="nav-item py-2 py-md-0">
                            <a className="nav-link dropdown-toggle mx-auto" onClick={handlerSignOut} href="https://clientes.eleg.app/">Ir a perfil</a>
                        </li>
                        <li className="nav-item py-2 py-md-0">
                            <a href="https://eleg.app/contacto" className="btn btn-contacto-circle" data-toggle="tooltip" data-placement="top" title="" data-original-title="Contacto">
                                <i className="fas fa-envelope" data-tip data-for="contactTip"></i>
                                <span className="d-lg-none">Contacto</span>
                                <ReactTooltip id="contactTip" place="bottom" effect="solid" type="dark">
                                    Contacto
                                </ReactTooltip>
                            </a>
                        </li>
                        <li className="nav-item py-2 py-md-0 pt-1">
                            <a className="main-dropdown-estru my-auto mx-auto" onClick={openModal} href="#!" id="mainDropdownEstru" data-toggle="dropdown" aria-expanded={`${modalOpener ? 'true' : 'false'}`}>
                                <img className="img-fluid pt-1" src="https://eleg.app/themes/elegsquad/assets/images/logo-estrucplan.png" />
                            </a>
                            <div aria-labelledby="mainDropdownEstru" className={`dropdown-menu ${modalOpener ? 'show' : ''} dropdown-menu-right `}>
                                <a href="https://estrucplan.com.ar/" className="dropdown-item text-uppercase">
                                    <small>Estrucplan Consultora</small>
                                </a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item" href="https://estrucplan.com.ar/home-medio-ambiente/">Medio Ambiente</a>
                                <a className="dropdown-item" href="https://estrucplan.com.ar/home-seg/">Seguridad e Higiene</a>
                                <a className="dropdown-item" href="https://estrucplan.com.ar/elab/">Laboratorio</a>
                                <a className="dropdown-item" href="https://eleg.app/">Legislación</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>)
}

export default Header;