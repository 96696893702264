import React from 'react';
import InputText from './InputText';

class InputPalabras extends React.Component {

    render() {
        return (
            <div className="col-lg-4">
                <div className="form-group mb-4">
                    <InputText id="palabrasInput" label="Busqueda por palabras" placeholder="Introduzca nombre o descripción" idModal='#mejorarPlanModalBusquedaPalabras' defaultValue="" {...this.props} />
                </div>
            </div>
        )
    }
}

export default InputPalabras;