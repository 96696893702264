import React from 'react';
import DownloadPDFButton from './DownloadPDFButton';

const BASE_MODAL_URL = process.env.BASE_MODAL_URL || "seeMoreModal";

class Modal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            norm: props.value || {},
            index: props.index || 0,
        }
    }
    isActive(active) {
        if (active) {
            return "Si";
        } else {
            return "No";
        }
    }

    renderProvincia(norm) {
        if (norm !== null) {
            return <div className="badge">
                <span className="col-label">Provincia </span>
                <span className="col-title">{norm}</span>
            </div>
        }
    }

    renderMunicipio(norm) {
        if (norm !== null) {
            return <div className="badge">
                <span className="col-label">Municipio </span>
                <span className="col-title">{norm}</span>
            </div>
        }
    }

    renderRegion(norm) {
        if (norm !== null && norm !== '-') {
            return <div className="badge">
                <span className="col-label">Region </span>
                <span className="col-title">{norm}</span>
            </div>

        }

    }

    renderFechaPublicacion(norm) {
        if (norm !== null) {
            const parsedNorm = norm.split(' ')[0].replace(' ');
            return <div className="badge">
                <span className="col-label">Fecha de Publicacion </span>
                <span className="col-title">{parsedNorm}</span>
            </div>
        }
    }

    render() {
        return <div key={`modal${this.state.index}-${this.state.norm.name}`} className="modal fade" id={`${BASE_MODAL_URL}${this.state.index}`} aria-hidden="true"
            aria-labelledby={`${BASE_MODAL_URL}${this.state.norm.name}`} tabIndex="-1">
            <div className="modal-dialog modal-lg modal-dialog-centered modal-alert">
                <div className="modal-content">
                    <div className="modal-header px-4 pb-0 border-0">
                        <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                    </div>
                    <div className="card border-0 px-4 bg-transparent mb-3">
                        <div className="card-header">
                            <div className="card-header-text">
                                <span className="col-label">Norma </span>
                                <span className="col-title"><strong>{this.state.norm.name}</strong></span>
                                <div className="card-badge-status ">
                                    <span className="badge badge-dark">Vigente: {this.isActive(this.state.norm.active)}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card-header-text-info ml-3">
                            <div className="card-header-text">
                                <span className="col-label">Organismo emisor</span>
                                <span className="col-title">{this.state.norm.issuer}</span>
                            </div>
                            <div className="card-header-tags">
                                <div className="badge">
                                    <span className="col-label">Alcance </span>
                                    <span className="col-title">{this.state.norm.scope}</span>
                                </div>
                                {this.renderRegion(this.state.norm.l2)}
                                {this.renderProvincia(this.state.norm.l3)}
                                {this.renderMunicipio(this.state.norm.l4)}
                                {this.renderFechaPublicacion(this.state.norm.date)}
                            </div>
                            <div className="card-body">
                                <h5 className="card-title">{this.state.norm.title}</h5>
                                <p className="card-text">{this.state.norm.body}</p>
                            </div>
                        </div>
                    </div>

                    <div className="card-header-btns pb-4">
                        <div className="d-flex justify-content-end px-4">
                            <DownloadPDFButton value={this.state.norm} index={this.state.index} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default Modal;