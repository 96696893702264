import React from 'react';
import { Text, useTranslator } from "@eo-locale/react";

const FormControl = (props) => {
    return (
        <div className="col-lg-2">
            <div className="form-group mb-4"  {...props} />
        </div>
    )
}

const Label = (props) => {
    return <label {...props} />
}

const Input = (props) => {
    return <input type="text" className="form-control" {...props} />
}

const InputForm = (props) => {
    const translator = useTranslator();
    const {
        idFormControl,
        idTextLocalization,
        onChange,
        value,
    } = props;
    const placeholder = props.placeholder ? props.placeholder : '';

    const handleOnChange = (e) => {
        e.preventDefault();
        if (onChange) {
            onChange(e);
        }
    }

    return (
        <FormControl>
            <Label htmlFor={idFormControl}><Text id={idTextLocalization} /></Label>
            <Input
                value={value}
                placeholder={translator.translate(placeholder)}
                onChange={handleOnChange} id={idFormControl} />
        </FormControl>
    );
};

export default InputForm;
