import React from 'react';
import {Text} from "@eo-locale/react";

const Button = (props) => {
    return <div {...props} />
}

const Link = (props) => {
    return <div {...props} />
}

const Modal = (props) => {
    return <div {...props} />
}

const ModalBody = (props) => {
    return <div {...props} />
}

const ModalCloseButton = (props) => {
    return <div {...props} />
}

const ModalContent = (props) => {
    return <div {...props} />
}

const ModalFooter = (props) => {
    return <div {...props} />
}

const ModalHeader = (props) => {
    return <div {...props} />
}

const ModalOverlay = (props) => {
    return <div {...props} />
}

const Center = (props) => {
    return <div {...props} />
}

const TextChakra = (props) => {
    return <p {...props} />
}

const MessageSuccess = () => {
    return (
        <Center d={"flex"} flexDirection={"column"} mt={'5rem'}>
            <TextChakra fontSize={{md: '36px', base: '20px'}} fontWeight={"semibold"}>El presupuesto fue solitado con
                éxito</TextChakra>
            <TextChakra>Nos contactaremos con usted a la brevedad</TextChakra>
            <Button
                width={'25%'}
                bgGradient="linear(#ADA34D 0%,#D6C851 100%)"
                borderRadius={29}
                color={"white"}
                _hover={{
                    bgGradient: "linear(#ADA34D 0%,#D6C851 50%)"
                }}
                _active={{
                    bgGradient: "linear(#ADA34D 0%,#D6C851 30%)"
                }}
                p={'25px'}
                mt={'2rem'}
            >
                <Text id={"GoBack"}/>
            </Button>
        </Center>
    );
};

export default MessageSuccess;
