import React from 'react';
import InputText from './InputText';

class InputNroNorma extends React.Component {

    render() {
        return (
            <div className="col-lg-2">
                <div className="form-group mb-4">
                    <InputText id="nroNorma" label="Nro. Norma" placeholder="" defaultValue="" {...this.props} />
                </div>
            </div>
        )
    }
}

export default InputNroNorma;