import React from 'react';
import InputText from './InputText';

class InputYear extends React.Component {

    render() {
        return (
            <div className="col-lg-2">
                <div className="form-group mb-4">
                    <InputText id="year" label="Año" placeholder="" defaultValue="" {...this.props} />
                </div>
            </div>
        )
    }
}

export default InputYear;