import React, { useContext } from 'react';
import { userContext } from "../App";

const ImagenBanner = () => {

    const { user } = useContext(userContext);

    // FIXME: Obtener userPlan de userContext
    const userPlan = "free";

    // FIXME: Obtain from config
    const freeTierMonthlySearchs = 5;
    const freeTierResultLimit = 10;

    const name = (user && user.profile && user.profile.given_name) ? (" " + user.profile.given_name) : "";

    //FIXME: Que deberia aparecer cuando el usuario es Premium? Que otros tipos de usuario hay ademas de free y premium?
    return (
        <section className="internal-header header-plan-avanzado d-flex align-items-end">
            <div className="container">
                <div className="row d-flex align-items-center justify-content-between">
                    <div className="col-12">
                        <h1 className="title pt-5 pt-md-0">¡Hola{name}!</h1>
                        {(userPlan === "free") ?
                            <p className="text-white">En tu <strong>Plan Basico</strong> tenés hasta {freeTierMonthlySearchs} busquedas por mes y {freeTierResultLimit} resultados.<br />Cambiate a Premium y buscá sin límite y con filtros avanzados.</p>
                            :
                            <p className="text-white">En tu <strong>Plan Avanzado</strong><br />¡Buscá sin límite y con filtros avanzados!</p>
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ImagenBanner;
