import React from 'react';
import { getLevel4 } from '../../Services/ElegService';
import InputLevel from './InputLevel';

class InputLevel4 extends React.Component {
    render() {
        return (
            <div className="col-lg-4">
                <div className="form-group mb-4">
                    <InputLevel {...this.props} id="level4" callback={(value)=>getLevel4(this.props.p1, this.props.p2, value)} bar={3} />
                </div>
            </div>
        )
    }
}

export default InputLevel4;
