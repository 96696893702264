const Locales = [
    {
        language: 'en',
        messages: {
            Country: 'Country',
            Scope: 'Scope',
            NormType: 'Norm Type',
            Region: 'Region',
            Year: 'Year',
            nNorm: "No. Norm",
            Search: "Search",
            TextFilterAdvance: "Show advanced filters",
            searchByWord: "Search By Word",
            UpgradeYourPlan: "Upgrade your plan!",
            ContractAdvancedPlan: "Contract Advanced Plan",
            ContinueFreeSearch: "Continue Free Search",
            EnterNameOrDescription: "Enter Name Or Description",
            NameAndLastname:'Name and Lastname',
            Email:"Email",
            CorporateName:"Corporate Name",
            Tel:"Tel",
            ContactTime:"Contact Time",
            Message:"Message",
            NoResults:"No results were found for your search.",


        }
    },
    {
        language: 'es',
        messages: {
            Country: 'Pais',
            Scope: 'Alcance',
            NormType: 'Tipo de norma',
            Region: 'Region',
            Year: 'Año',
            nNorm: "Nro. Norma",
            Search: 'Buscar',
            TextFilterAdvance: 'Mostrar filtros avanzados',
            searchByWord: "Busqueda por palabra",
            UpgradeYourPlan: "¡Mejorá tu plan!",
            ContractAdvancedPlan: "Contratar Plan Avanzado",
            ContinueFreeSearch: "Continuar con la búsqueda gratuita",
            EnterNameOrDescription:"Introduzca nombre o descripción",
            NameAndLastname:'Nombre y apellido',
            Email:"Email",
            CorporateName:"Razon social",
            Tel:"Tel",
            ContactTime:"Horario de contacto",
            Message:"Mensaje",
            NoResults:"No se encontraron resultados para su busqueda.",

        }
    }
];

export default Locales;