import React from 'react';


class ModalEnhancementPlan extends React.Component {

    render() {
        return <div className="modal fade" id={this.props.id ? this.props.id : "mejorarPlanModal"} tabindex="-1" aria-labelledby="mejorarPlanModalLabel1" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-alert">
                <div className="modal-content">
                    <div className="modal-header px-4 pb-0 border-0">
                        <h5 className="modal-title text-center pt-4 pl-4 w-100" id="mejorarPlanModalLabel">
                            ¡Mejorá tu plan!
                        </h5>
                        <button type="button" className="close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body px-4 text-center">
                        <p>{this.props.text}</p>
                    </div>
                    <div className="pb-5 px-4 border-0">
                        <div className="px-4 w-100 text-center"><a href="https://eleg.app/plan-avanzado" type="button" className="btn btn-primary px-4 pt-2">Contratar Plan Avanzado</a></div>
                        <div className="px-4 w-100 text-center pt-4"><a className="text-link" data-bs-dismiss="modal">Continuar con la búsqueda gratuita</a></div>
                    </div>
                </div>
            </div>
        </div >
    }
}
export default ModalEnhancementPlan;