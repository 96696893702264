import React from 'react';
import { getNormType } from '../../Services/ElegService';

const DisabledSelect = (props) => {
    return <select disabled {...props} />
}

const EnabledSelect = (props) => {
    return <select {...props} />
}

const emptyItem = {
    key: "0",
    label: "",
    text: "",
    value: "",
}
class InputNormType extends React.Component {

    constructor(props) {
        super(props);
        this.parentSetter = props.foo;
        this.state = {
            value: "",
            options: [
                { key: "Cargando", value: "Cargando" }
            ],
            enabled: false,

            arguments: "",
            warning: props.warning,
        }

        this._handleChange = this._handleChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    _handleChange(value) {
        this.setState({ value: value });
        if (this.parentSetter) {
            this.parentSetter(value);
        }
    }

    handleChange(event) {
        this._handleChange(event.target.value);
    }

    componentDidMount() {
        const args = this.props.bar;
        if (args) {
            const p = getNormType(args);
            p.then(res => {
                this.setState({
                    options: res.sort((a, b) => {
                        if (a.value > b.value) {
                            return 1;
                        }
                        if (a.value < b.value) {
                            return -1;
                        }
                        return 0;
                    }),
                    enabled: true
                })
                this._handleChange(res[0].value);
            })
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.bar !== prevProps.bar) {
            this.setState({
                enabled: false
            })
            const p = getNormType(this.props.bar);
            p.then(res => {
                //Agrego un campo vacio
                res.unshift(emptyItem);

                this.setState({
                    options: res.sort((a, b) => {
                        if (a.value > b.value) {
                            return 1;
                        }
                        if (a.value < b.value) {
                            return -1;
                        }
                        return 0;
                    }),
                    enabled: true
                })
                this._handleChange(res[0].value);
            })
        }
        if (this.props.warning !== prevProps.warning) {
            this.setState({
                warning: this.props.warning
            })
        }
    }

    render() {

        const selectProps = {
            id: "normType",
            className: `custom-select`,
            value: this.state.value,
            onChange: this.handleChange,
            children: this.state.options.map(function (item) {
                let optionProps = {
                    key: item.key,
                    value: item.value,
                    children: item.value,
                };

                return <option {...optionProps} />
            })
        }

        return (
            <div className="col-lg-4">
                <div className="form-group mb-4">
                    <label htmlFor="normType">Tipo de norma</label>
                    {this.state.enabled ? <EnabledSelect {...selectProps} /> : <DisabledSelect {...selectProps} />}
                </div>
            </div>
        )
    }
}

export default InputNormType;
