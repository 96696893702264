const WarningMessage = (props) => {
  return (
    <div className={props.warning ? "card mb-5 alert-warning border-warning" : "card card-notice mb-5"} >
      <div className="card-body d-flex align-items-center">
        <div className="pr-3 text-gray-light">
          <svg xmlns="http://www.w3.org/2000/svg" fill={props.warning ? "#856404" : "currentColor"} className="bi bi-info-circle" viewBox="0 0 16 16" height="45" width="45">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
          </svg>
        </div>
        <div>
          <h5 className="card-title mb-0">Importante</h5>
          <p className="mb-0">{props.text}</p>
        </div>
      </div>
    </div >
  )
}

export default WarningMessage;