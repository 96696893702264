import React, {useEffect, useState} from 'react';
import {ResultCard} from "../Components";
import {IoIosArrowBack} from "react-icons/io";
import {IoIosArrowForward} from "react-icons/io";
// import {
//     Paginator,
//     Previous,
//     usePaginator,
//     Next,
//     PageGroup
// } from "chakra-paginator";
import Loading from "./Loading";

const usePaginator = () => {
    return {
        pagesQuantity: 1,
        currentPage: 1,
        setCurrentPage: 1,
        pageSize: 1,
        offset: 1
    }
}

const Box = (props) => {
    return <div {...props} />
}

const Center = (props) => {
    return <div {...props} />
}

const Paginator = (props) => {
    return <div {...props} />
}

const Previous = (props) => {
    return <a {...props} />
}

const Next = (props) => {
    return <a {...props} />
}

const PageGroup = (props) => {
    return <div {...props} />
}

/**
 * estilos de los botones de la paginacion
 * */
const baseStyles = {
    w: 7,
    fontSize: "sm"
};

const normalStyles = {
    ...baseStyles,
    _hover: {
        bg: "grey.300"
    },
    bg: "white.300"
};

const activeStyles = {
    ...baseStyles,
    _hover: {
        bg: "linear-gradient(180deg, #ADA34D 0%, #D6C851 80%)"
    },
    bg: "linear-gradient(180deg, #ADA34D 0%, #D6C851 100%)"
};

const separatorStyles = {
    w: 7,
    bg: "green.200",
    border: 'none'
};

const ResultList = (props) => {
    const outerLimit = 2;
    const innerLimit = 2;
    const [list, setList] = useState([]);
    const {
        pagesQuantity,
        currentPage,
        setCurrentPage,
        pageSize,
        offset
    } = usePaginator({
        total: props.lenght,
        initialState: {
            pageSize: 4,
            currentPage: 1
        }
    })
    useEffect(() => {
        if (props.result){
            let listAux = props.result.map((norma, index) => <ResultCard property={norma} key={index}/>)
            setList(listAux);
        }
    }, [props.result]);

    const handlePageChange = (nextPage) => {
        setCurrentPage(nextPage);
    };

    useEffect(() => {
        props.handlePagination(pageSize, offset)
    }, [currentPage]);

    return (
        <Box w="100%" marginTop={"3"}>
            <Center display={"flex"} flexDirection={"column"}>
                {!props.loadingResult ?
                    list
                    :
                    <Loading/>
                }
                <Paginator
                    isDisabled={props.loadingResult}
                    innerLimit={innerLimit}
                    currentPage={currentPage}
                    outerLimit={outerLimit}
                    pagesQuantity={pagesQuantity}
                    onPageChange={handlePageChange}
                    activeStyles={activeStyles}
                    normalStyles={normalStyles}
                    separatorStyles={separatorStyles}
                >
                    <Box  d={"flex"} justifyContent={"center"} p={5}>
                        <Previous rightIcon={<IoIosArrowBack/>} backgroundColor={'white'} _hover={{background:'white'}}>
                        </Previous>
                        <PageGroup isInline align="center"/>
                        <Next leftIcon={<IoIosArrowForward/>} backgroundColor={'white'} _hover={{background:'white'}}>
                        </Next>
                    </Box>
                </Paginator>
            </Center>
        </Box>
    );
};

export default ResultList;
