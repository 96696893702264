import React from 'react';
import { getIssuer } from '../../Services/ElegService';

const DisabledSelect = (props) => {
    return <select disabled {...props} />
}

const EnabledSelect = (props) => {
    return <select {...props} />
}

const emptyItem = {
    key: "",
    label: "",
    text: "",
    value: "",
}

const NO_APLICABLE = "No aplicable";
const FALTA_DEFINICION = "Falta definicion";

class InputIssuer extends React.Component {

    constructor(props) {
        super(props);
        this.parentSetter = props.foo;
        this.state = {
            value: "",
            options: [
                { key: "Cargando", value: "Cargando" }
            ],
            filteredOptions: [
                { key: "Cargando", value: "Cargando" }
            ],
            enabled: false,
            warningLevel: 1,
            arguments: "",
            hasSecondLevel: false,
        }

        this._handleChange = this._handleChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.renderWarning = this.renderWarning.bind(this);
    }

    _handleChange(value) {
        this.setState({ value: value });
    }

    handleChange(event) {
        this._handleChange(event.target.value);
    }

    componentDidMount() {
        const args = this.props.bar;
        if (args) {
            const p = getIssuer(args);
            p.then(res => {
                //Me fijo si existe la region 2
                const someSecondLevelIndex = res.findIndex(e => e.level === "2");
                //Agrego un campo vacio
                res.unshift(emptyItem);
                this.setState({
                    options: res,
                    enabled: true,
                    hasSecondLevel: someSecondLevelIndex !== -1,
                })
                this._handleChange(res[0].value);
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.bar !== prevProps.bar) {
            this.setState({
                enabled: false
            })
            const p = getIssuer(this.props.bar);
            p.then(res => {
                //Me fijo si existe la region 2
                const someSecondLevelIndex = res.findIndex(e => e.level === "2");
                //Agrego un campo vacio
                res.unshift(emptyItem);
                this.setState({
                    options: res,
                    enabled: true,
                    hasSecondLevel: someSecondLevelIndex !== -1,
                })
                this._handleChange(res[0].value);
            })
        }
        if (this.state.value !== prevState.value) {
            if (this.parentSetter) {
                this.parentSetter(this.state.value);
            }
        }
        const compareUpdateOnPerField = (a, b) => (
            a.country !== b.country
            || a.level2 !== b.level2
            || a.level3 !== b.level3
            || a.level4 !== b.level4
            || a.scopeLevel !== b.scopeLevel
        )
        const b1 = compareUpdateOnPerField(this.props.updateOn, prevProps.updateOn);
        const b2 = (this.state.options !== prevState.options);
        if (b1 || b2) {
            const scopeLevel = this.props.updateOn.scopeLevel;
            if (scopeLevel) {
                const level1 = this.props.updateOn.country;
                if (level1) {
                    if (scopeLevel > 1) {
                        const level2 = this.props.updateOn.level2;
                        if (level2) {
                            if (scopeLevel > 2) {
                                const level3 = this.props.updateOn.level3;
                                if (level3) {
                                    if (scopeLevel > 3) {
                                        const level4 = this.props.updateOn.level4;
                                        if (level4) {
                                            // Cuarto filtro. Mostrar organismos (nivel4) de level4, level3, level2 y level1
                                            const filteredOptions = this.state.options.filter(
                                                e => e.level === "4" && e.l2 === level2 && e.l3 === level3 && e.l4 === level4
                                            ).sort((a, b) => {
                                                if (a.value > b.value) {
                                                    return 1;
                                                }
                                                if (a.value < b.value) {
                                                    return -1;
                                                }
                                                return 0;
                                            });
                                            filteredOptions.unshift(emptyItem)
                                            this.setState({
                                                filteredOptions: filteredOptions,
                                                warningLevel: 0,
                                                value: ""
                                            });
                                        } else {
                                            // Setear el filtered options para que ponga 'Falta definicion' y que este deshabilitado.
                                            // Se tiene que renderizar el cartel de warning 2
                                            this.setState({
                                                filteredOptions: [
                                                    { key: FALTA_DEFINICION, value: FALTA_DEFINICION }
                                                ],
                                                warningLevel: 2,
                                                value: ""
                                            })
                                        }
                                    } else {
                                        // Tercer filtro. Mostrar organismos (nivel3) de level3, level2 y level1
                                        const filteredOptions = this.state.options.filter(this.state.hasSecondLevel
                                            ? (e => e.level === "3" && e.l2 === level2 && e.l3 === level3)
                                            : (e => e.level === "4" && e.l3 === level2 && e.l4 === level3)
                                        ).sort((a, b) => {
                                            if (a.value > b.value) {
                                                return 1;
                                            }
                                            if (a.value < b.value) {
                                                return -1;
                                            }
                                            return 0;
                                        });
                                        filteredOptions.unshift(emptyItem)
                                        this.setState({
                                            filteredOptions: filteredOptions,
                                            warningLevel: 0,
                                            value: ""
                                        });
                                    }
                                } else {
                                    // Setear el filtered options para que ponga 'Falta definicion' y que este deshabilitado.
                                    // Se tiene que renderizar el cartel de warning 2
                                    this.setState({
                                        filteredOptions: [
                                            { key: FALTA_DEFINICION, value: FALTA_DEFINICION }
                                        ],
                                        warningLevel: 2,
                                        value: ""
                                    })
                                }
                            } else {
                                // Segundo filtro. Mostrar organismos Provinciales (nivel2) de level2 y level1
                                const filteredOptions = this.state.options.filter(this.state.hasSecondLevel
                                    ? (e => e.level === "2" && e.l2 === level2)
                                    : (e => e.level === "3" && e.l3 === level2)
                                ).sort((a, b) => {
                                    if (a.value > b.value) {
                                        return 1;
                                    }
                                    if (a.value < b.value) {
                                        return -1;
                                    }
                                    return 0;
                                });
                                filteredOptions.unshift(emptyItem)
                                this.setState({
                                    filteredOptions: filteredOptions,
                                    warningLevel: 0,
                                    value: ""
                                });
                            }
                        } else {
                            // Setear el filtered options para que ponga 'Falta definicion' y que este deshabilitado.
                            // Se tiene que renderizar el cartel de warning 2
                            this.setState({
                                filteredOptions: [
                                    { key: FALTA_DEFINICION, value: FALTA_DEFINICION }
                                ],
                                warningLevel: 2,
                                value: ""
                            })
                        }
                    } else {
                        // Primer filtro. Mostrar organismos Nacionales
                        const filteredOptions = this.state.options.filter(e => e.level === "1").sort((a, b) => {
                            if (a.value > b.value) {
                                return 1;
                            }
                            if (a.value < b.value) {
                                return -1;
                            }
                            return 0;
                        });
                        if (filteredOptions[0]) {
                            filteredOptions[0] = emptyItem;
                        }
                        this.setState({
                            filteredOptions: filteredOptions,
                            warningLevel: 0,
                            value: ""
                        });
                    }
                } else {
                    // Setear el filtered options para que ponga 'Falta definicion' y que este deshabilitado.
                    // Se tiene que renderizar el cartel de warning 2
                    this.setState({
                        filteredOptions: [
                            { key: FALTA_DEFINICION, value: FALTA_DEFINICION }
                        ],
                        warningLevel: 2,
                        value: ""
                    });
                }
            } else {
                // Setear el filtered options para que ponga 'No aplicable' y que este deshabilitado.
                // Se tiene que renderizar el cartel de warning.
                this.setState({
                    filteredOptions: [
                        { key: NO_APLICABLE, value: NO_APLICABLE }
                    ],
                    warningLevel: 1,
                    value: ""
                })
            }
        }
    }

    renderWarning() {
        const warningLevel = this.state.warningLevel;
        return (
            <div className="col-lg-4">
                {warningLevel === 1 ?
                    (
                        <div className="alert alert-warning">Para seleccionar <b>Organismo Emisor</b> es condición previa seleccionar <b>Alcance</b></div>
                    ) : (
                        <div className="alert alert-warning">Para seleccionar <b>Organismo Emisor</b> es condición previa completar el <b>Alcance</b></div>
                    )
                }
            </div>
        )
    }

    render() {
        
        const selectProps = {
            id: "issuer",
            className: "custom-select",
            value: this.state.value,
            onChange: this.handleChange,
            children: this.state.filteredOptions.map(function (item) {
                let optionProps = {
                    key: item.key,
                    value: item.key,
                    children: item.value,
                };

                return <option {...optionProps} />
            })
        }

        return (<>
            <div className={`col-lg-4 ${this.props.premium ? '' : 'content-blocked'}`} data-bs-toggle='modal' data-bs-target={this.props.premium ? '' : `#mejorarPlanModalOrganismo`}>
                <div className="form-group mb-4">
                    <label htmlFor="issuer">Organismo Emisor</label>
                    {(this.state.enabled && !this.state.warningLevel && this.props.premium) ? <EnabledSelect {...selectProps} /> : <DisabledSelect {...selectProps} />}
                </div>
            </div>
        </>
        )
    }
}

export default InputIssuer;
